<script>

    import { onMount } from "svelte";
    import { get } from 'svelte/store';
    import { devices, meetEvents } from './stores.js'

    import { nanoid } from 'nanoid/non-secure'
    import { DevicesInterface } from "./devicesInterface.js";

    export let label; //device label or 'toggle'
    export let selected;
    export let type;
    export let isToggleItem = false;
    
    let color;
    let textShadow = "inherit";
    let isHovering = false;

    const MOUSE_OVER_COLOR = "#6a6a6afc"; //rgba(171, 168, 168, 0.987)
    const SELECTED_COLOR = "#6a6a6afc";
    const SELECTED_TEXT_SHADOW = "1px 1px 2px red, 0 0 1em blue, 0 0 0.2em blue";
    const MUTE_TOGGLE_COLOR = "red";
    
    const mouseEnter = (e) => {
        
        isHovering = true;
        if (!isToggleItem){
            color = MOUSE_OVER_COLOR;
            // textShadow = SELECTED_TEXT_SHADOW;
        }
            
    }

    const mouseLeave = () => {
        
        isHovering = false;
        if (!isToggleItem)
            isSelected();
    }

    const isSelected = () => {
        if (selected){
            // textShadow = SELECTED_TEXT_SHADOW;
            color = SELECTED_COLOR;
        }

        else {
            color = "inherit";
            // textShadow = "inherit";
        }

        // selected ? color = SELECTED_COLOR : color = "inherit";
    }

    const deviceItemClick = async (e) => {
        
        //so we dont trigger click handler in the parent
        e.stopPropagation();
        
        //the menu is open but we are not hovering on any of the names
        if (!isHovering)
            return;
        
        const selected = get(devices).selected;
        meetEvents.add({type: 'hideDeviceMenus', target: 'session', eventId: nanoid(), hideType: type});

        //check this is not the already selected item
        if (selected[type].label !== label){
            
            const muteMapped = DevicesInterface.mapMuteToggleLabels(type);
            
            if (muteMapped.includes(label) && type === 'camera'){
                meetEvents.add({type: 'openCameraSpinner', target: 'session', eventId: nanoid()}); 
            }
                
            const result = await DevicesInterface.changeDevice(type, label);
            
            meetEvents.add({type: 'closeCameraSpinner', target: 'session', eventId: nanoid()});
            if (result !== 'device change')
                meetEvents.add({type: 'toggleIcons', target: 'session', eventId: nanoid(), deviceType: type, isMuted: result}); 
        }
    }

    onMount(() => {

        isSelected();
        if (isToggleItem)
            color = MUTE_TOGGLE_COLOR;

    })

</script>

    <p style="color: {color}; text-shadow: {textShadow}" on:click={deviceItemClick} on:mouseenter={mouseEnter} on:focus={mouseEnter} on:mouseleave={mouseLeave}>
        {label}
    </p>


<style>

    p {
        margin-right: 0px;
        margin-left: 0px;
        margin-top: 0px;
        margin-bottom: 0.5em;
        width: 100%;
    }

</style>