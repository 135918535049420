
import { get } from 'svelte/store' 
import { devices } from './stores.js'

//this should live in the start menu
//eventually make this nicer
export function generateDeviceMenu(devices, userType, disableLabel, micMenu, speakerMenu, cameraMenu, streamMenu){

    for (const devType in devices){

        let menuType;
        if (devType === 'mic') menuType = micMenu;
        else if (devType === 'speaker') menuType = speakerMenu;
        else if (devType === 'camera') menuType = cameraMenu;
        else continue
        
        if (menuType){
            
            let children = menuType.children
            let current = Array.from(children).map(x => x.label)
            
            devices[devType].forEach((d, i) => {
                
                if (!current.includes(d.label)){
                    
                    const opt = document.createElement('option');
                    opt.innerText = d.label;
                    current.push(d.label)
                    menuType.appendChild(opt)
                
                    if (devType === 'speaker' && userType === 'eng'){
                        //Eng works
                        const outOpt = document.createElement('option');
                        outOpt.innerText = d.label;
                        streamMenu.appendChild(outOpt)
                    }
                }
            })

            if (devType === 'camera'){
                const opt = document.createElement('option');
                opt.innerText = disableLabel;
                menuType.appendChild(opt);
            }

            if (devType === 'speaker' && devices[devType].length === 0){
                const sp_opt = document.createElement('option');
                sp_opt.innerText = 'Default';
                menuType.appendChild(sp_opt);

                if (userType === 'eng'){
                    const st_opt = document.createElement('option');
                    st_opt.innerText = 'Default';
                    streamMenu.appendChild(st_opt)
                }
                
            }

            for (const opt of document.querySelectorAll('option')){
                if (opt.label === '...Loading...')
                    opt.parentElement.removeChild(opt)
            }   
        }
    }

}

//this should live in start panel
export function displayMenus(emptyMenus){

    const savedMenus = get(devices).menus
    
    for (const type in emptyMenus){
        
        const emptyMenu = emptyMenus[type];
        const savedMenu = savedMenus[type];
        
        if (savedMenu){
            
            let newIdx = 0;
            let i = 0;
            let refLabel = savedMenu[savedMenu.selectedIndex].innerText;
            
            for (const item of savedMenu){
                if (item.innerText === refLabel){
                    newIdx = i;
                }

                const opt = document.createElement('option');
                opt.innerText = item.innerText;

                i++;
                emptyMenu.appendChild(opt);
            }
            
            emptyMenu.selectedIndex = newIdx;
        }
    }
}

//s
// export async function toggleAudioMute(localTracks){
    
//     let isMuted = true;
//     for (const track of localTracks){
//         if (track.type === 'audio'){
            
//             if (track.isMuted()){
//                 track.unmute();
//                 isMuted = false;
//                 OutputConfig.talentMuted = isMuted;
//             }
//             else {
//                 track.mute();
//                 OutputConfig.talentMuted = true;
//             }

//             meetEvents.update(events => {
//                 events.push({type: 'localMicToggle', id: 'local', isMuted: isMuted});
//                 return events;
//             })
        
//         }
//     }

// }

//s
// export async function stopAndRemoveVideo(localTracks, disabledExplicit = false){

//     if (disabledExplicit){ 
//         devices.updateSelectedsObj({camera: {id: null, label: "Disable Camera"}})
//         devices.updateMenusSelected({type: "camera", label: "Disable Camera"});
//     }
    
//     localTracks.forEach(async track => {
//         if (track.type === 'video'){
//             await track.mute(); //fires conference event for remote peers
//             await track.dispose();
//             ///await CallApi.jitsiConference.removeTrack(track);
//             track.track.stop();
            
//         }
//     })

//     // if (updateUI){
//     //     meetEvents.update(events => {
//     //         events.push({type: 'localMuteToggle', id: 'local', trackType: 'video'})
//     //         return events
//     //     })
//     // }

// }

//s
// export async function addAndStartVideo(track = null){

//     if (!track){
        
//         //recall the last selection, if there is none, use the default
//         const camSelected = get(devices).selected.camera;
//         let label, deviceId;
//         if (camSelected.label === "Disable Camera"){
//             label = "default";
//             deviceId = "default";
//         }
        
//         else {
//             label = camSelected.label;
//             deviceId = devices.getId({type: "camera", label: label}).id
//         }

//         let newTracks = await CallApi.getLocalTracksCombine({camera: {id: deviceId, label: label}});
//         track = newTracks.camera
//         if (!track){
//             console.error('Error Getting Conference Track')
//         }
//     }

//     await CallApi.jitsiConference.addTrack(track)
    
//     localTracks.update(_localTracks => {
//         _localTracks.camera = track;
//         return _localTracks;
//     })

//     //update selected devices store
//     devices.updateSelectedsObj({camera: {id: track.track.id, label: track.track.label}})
//     devices.updateMenusSelected({type: "camera", label: track.track.label});

//     // if (updateUI){
//     //     meetEvents.update(events => {
//     //         events.push({type: 'localMuteToggle', id: 'local', trackType: 'video'})
//     //         return events
//     //     })
//     // }
            
// }

//startPanel
// export async function openStreams(userType){

//     const allDevices = get(devices);
//     let selectedTracks = await CallApi.getLocalTracksCombine(allDevices.selected);
    
//     if (userType === 'talent'){
//         Dispatcher.openTalentStream(allDevices.selected.mic.id);
//         selectedTracks.mic.track.talent = true;
//         console.log("TALENT TRACK ", selectedTracks.mic)
//     }
    
    
//     await CallApi.jitsiConference.addTrack(selectedTracks.mic)

//     if (selectedTracks.camera){
//         await CallApi.jitsiConference.addTrack(selectedTracks.camera)
//     }

//     return selectedTracks

// }