import { getUnixTime } from './utils';

export const BuildSettings = {
    
    //DEBUG SECTION
    DEBUG: false,
    DISABLE_CONFERENCE: false,
    enableLoopback: false, 
    talentPlaybackToggle: false,
    forceTURN: false,
    disableLocalTile: false,

    //PREFS
    nameToLclStorage: true //do we save entered name from last time

}

export const OutputConfig = {
    
    user: null, //'webrtc User ID
    userName: null, //'conference screen name'
    roomName: null, 

    acSinkId: null, 
    tileSinkIds: [],

    hasJoinedCall: false,
    hasJoinedSession: false,
    talentMuted: false,

    //prevVidList: [],
    isPlaying: false,
    talentConfIds: [],

    initInfo: {
        session: null, //{'status': 'active', 'ak': ak, 'sk': sk, 'sessionID': sessionID, 'bucket': bucket}
        urlResult: null, //{type: 'eng', 'client', 'talent', x: y }
        engInputUri: 'ws://localhost:4527', 
        streamUri: 'wss://postlink-ws-server.herokuapp.com/',
    },

    vidConfig: {
        aspectRatio: 0.546875, // Intrinsic, 720/1280
        codedWidth: 1280,
        codedHeight: 720,
        paintLenght: 10,
    },

    outputConfig: {
        sampleRate: null,
        frameRate: null,
        vidlist: [], //file ids and timeline metadata for each file [{clipStart: x, clipLength: x, clipStartOffset: x, clipEndOffset: x, fileLength: null, id: x, path: ''}, ]}
        session: null, //abbreviated session data for getting dl links {sessionID: session.id, bucket: session.bucket, dlcreds: {ak 'ak', sk 'sk'}}
        toUpload: [],
        toDownload: [],
        toCancel: [],
    },

    //session.auth is a list of authObjects
    //when we have a match we replace the list with the authObjects that represents the basis of a authorization
    isAuth(session) {

        //authorize with vip if it exists
        for (const authObj of session.auth){
            if (authObj.auth_status === 'active' && authObj.auth_type === 'vip'){
                session.auth = authObj;
                return true;              
            }
        }

        //authorize with active session if it exists
        for (const authObj of session.auth){
            if (authObj.auth_status === 'active' && authObj.auth_type === 'session' && getUnixTime() < authObj.end_time){
                session.auth = authObj;
                return true;              
            }
        }
    
        //authorize with active sub if it exists
        for (const authObj of session.auth){
            if (authObj.auth_status === 'active' && authObj.auth_type === 'sub'){
                session.auth = authObj;
                return true;
            }
        }
        
        //authorize with pending session if it exists
        for (const authObj of session.auth){
            if (authObj.auth_status === 'pending' && authObj.auth_type === 'session'){
                session.auth = authObj;
                return true;
            }
        }
            
        session.auth = {auth_status: 'inactive'};
        return false;
    },

    setAuthStartEnd(a, includes_trial=false){

        if (includes_trial){
            a.includes_trial = "false";
            a.start_time = String(getUnixTime());
            a.end_time = String(getUnixTime(30));
            return;
        }

        a.start_time = getUnixTime();
        a.end_time = a.start_time + Number(a.session_length);
        a.start_time = String(a.start_time);
        a.end_time = String(a.end_time);
    },

    sessionClock: {
        
        end: null,
        isAuth: false,
        
        init(a){
            this.end = Number(a.end_time);
            this.isAuth = true;
            console.log('Auth Starts Now, Ends: ', this.end)
        },

        getRemaining (){
            let secPerHr = (60 * 60);
            let s = this.end - getUnixTime();
            let h = Math.floor(s / secPerHr); 
            let m = Math.floor((s % secPerHr) / 60);
            
            if (h < 0)
                h = 0;
            if (m < 0)
                m = 0;
            if (s < 0)
                s = 0;    

            //s currently shows the total number of seconds for the entire session, so if we show seconds, we need to show only 0 - 59

            return {h: h, m: m, s: s}
        
        }
    },

}
