
<div id="nsMessageWrapper">
    <h1 id='nsMessage'>Only Desktop Chrome 110 or above is currently supported :(</h1>
</div>


<style>

#nsMessageWrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

#nsMessage {

    font-family:  "Helvetica", sans-serif;
    font-weight: 325;
    font-size: 24px;
    color: rgba(255, 255, 255, 0.891);
    
}



</style>
