
<script>

    import { onDestroy, onMount } from "svelte";
    import { chatMessages, localTracks, meetEvents, screenName } from './stores.js'
    import { get } from 'svelte/store';

    import { DevicesInterface } from './devicesInterface.js'

    import Tile from './Tile.svelte'
    import Chat from './Chat.svelte';
    import DeviceMenu from './DeviceMenu.svelte';

    import { CallApi } from './call-api.js'
    import { OutputConfig, BuildSettings } from './output-config.js';
    import { Lambdas } from './lambdas-api.js';

    export let setSpinner;
    export let userType;
    
    let micMuted = false;   
    let cameraMuted = false;
    let speakerMuted = false;
    let streamMuted = false;
    let cameraSpinner = false;

    let chatOpen = false;
    let hasLoaded = false;
    
    const menuState = {
        mic: false,
        camera: false,
        speaker: false, 
        stream: false
    }

    //const AUTH_CHECK_INTERVAL = 60000;
    const AUTH_CHECK_INTERVAL = 2000;

    //layout modes
    const SW_CHAT_OPEN = "65vw";
    const SW_CHAT_CLOSED = "65vw";
    let screenWidth = SW_CHAT_OPEN;
    let justifyTiles = "flex-start";

    let session;
    let tiles;
    let sessionClockInterval;
    let meetEventsCleanInterval;
    let strRemain = '..loading..';

    $: uiRemain = strRemain;
    $: mic_mute_state = micMuted ? 'fa-microphone-slash' : 'fa-microphone';
    $: vid_mute_state = cameraMuted ? 'fa-video-slash' : 'fa-video';
    $: speaker_mute_state = speakerMuted ? 'fa-volume-slash' : 'fa-volume';
    $: stream_mute_state = streamMuted ? 'fa-signal-stream-slash' : 'fa-signal-stream';


    const meetEventsUnsub = meetEvents.subscribe(meetEvents => {
        if(hasLoaded)
            checkEventQueue(meetEvents.events);
    })

    const toggleIcons = (type, result) => {
    
        type == 'mic' ? micMuted = result : 
        type == 'camera' ? cameraMuted = result :
        type == 'speaker' ? speakerMuted = result :
        streamMuted = result;

    }

    const toggleMutes = async (type) => {

        if (type === 'camera')
            cameraSpinner = true;

        try {
            let result = await DevicesInterface.toggleMute(type);
            if (type === 'camera')
                cameraSpinner = false;

            toggleIcons(type, result);

        } catch (error){

            if (cameraSpinner)
                cameraSpinner = false;
            
            console.error(`${type} Toggle Error: ${error}`);
        }

        finally{
            menuState[type] = false
        }

    }

    const toggleChat = () => chatOpen = !chatOpen;
    const showMenus = (type) => menuState[type] = true;
    const hideMenus = (type) => menuState[type] = false; 
    
    const sessionClockLoop = async () => {

        const sleep = (ms) => {return new Promise(resolve => setTimeout(resolve, ms))}
        let remaining = OutputConfig.sessionClock.getRemaining();

        while (remaining.s > 0){
            await sleep(AUTH_CHECK_INTERVAL)
            remaining = OutputConfig.sessionClock.getRemaining();


            if (remaining.m < 10)
                remaining.m = '0' + String(remaining.m);
            if (remaining.s < 10)
                remaining.s = '0' + String(remaining.s);

            //strRemain = String(remaining.h) + ':' + String(remaining.m) + ':' + String(remaining.s);
            strRemain = String(remaining.h) + ':' + String(remaining.m); //the value returned from getRemaining for seconds has a bug, 
        }

        remaining.h = 0;
        remaining.m = 0;
        remaining.s = 0;

        const contStatus = await canContinue();
        
        if (contStatus === 'session'){ 
            //the clock has been re-init by canContinue
            sessionClockLoop();
            return;
        } 

        else if (contStatus === 'sub')
            return;
            
        else if (contStatus === false) {

            console.error("No additional auths -- show buying modal here");
            OutputConfig.sessionClock.isAuth = false; //turns of webrtc i/o
            strRemain = "get another session or a subscription"; //session and subscriptions will be links to the stripe pages
            return;
        }
    
    }

    const canContinue = async () => {
    
        let a = OutputConfig.initInfo.session.auth;
        if (a.auth_type === 'sub'){

            //if this was a trial object, we update end_time locally and restart the clock and continue on.
            //we do this instead of checking the server because the server would take a minute or so to reflect the new end_time
            // if (a.includes_trial && a.includes_trial === "true"){
            //     setAuthStartEnd(a, true);
            //     OutputConfig.sessionClock.init(OutputConfig.initInfo.session.auth);
            //     console.log("Trial has ended, we are assuming the payment did not fail, which is ok");
            //     console.log("New End Time Is: ", OutputConfig.initInfo.session.auth.end_time)
            //     //return true;
            // }
            
            return 'sub'; //for now we will allow any subs to finish their session, even trials
        }

        //otherwise get any new auth objects from server
        const allResults = await Lambdas.initWebClient();
        console.log("New Auth Objects: ", allResults);
        OutputConfig.initInfo.session = allResults.session;

        //check if there are any auths at all
        if (OutputConfig.isAuth(OutputConfig.initInfo.session)){

            a = OutputConfig.initInfo.session.auth;

            if (a.auth_type === 'session'){
                console.log(`Have a session auth`);
                if (a.auth_status === 'pending'){ //if our session auth is pending, update the start_time and end_time on db
                    OutputConfig.setAuthStartEnd(a); //mutates passed in object
                    const result = await Lambdas.setSessionStartTime(a.room_name, a.auth_id, a.start_time, a.end_time);
                    console.log(`It's pending, so updated db: ${result}`);
                }
                
                OutputConfig.sessionClock.init(a);
                return 'session'
            }
    
            //otherwise our auth was a valid session object
            OutputConfig.sessionClock.init(a);        
            return 'sub';
        }

        console.log("CanContinue will return false: ", allResults);
        return false;
    }


    function checkEventQueue(events) {

        for (const [i, event] of events.entries()){
            
            if (event.target === 'session' && !event.consumed){
                
                try {

                    event.consumed = true;
                    switch (event.type){
                        
                        case 'createTile':
                            const tile = document.getElementById(event.id);
                            tiles && !tile ? createTile(event.id) : event.consumed = false;
                            break;

                        case 'hideDeviceMenus':
                            hideMenus(event.hideType);
                            break;

                        case 'toggleIcons':
                            toggleIcons(event.deviceType, event.isMuted);
                            break;
                            
                        case 'openCameraSpinner':
                            cameraSpinner = true;
                            break;

                        case 'closeCameraSpinner':
                            cameraSpinner = false;
                            break;
                    }

                    if (event.consumed)
                        meetEvents.remove(event)
                    

                } catch (error){

                    event.consumed = false; 
                    console.error(`Error executing tile event: ${error}`);

                }

            }

        }
    }

    function createTile(id){

        let name = '';
        if (id === 'local'){
            if (BuildSettings.disableLocalTile){
                console.error('Not Showing Local Tile!')
                return;
            }
                
            name = get(screenName);
        }

        else {
            const users = CallApi.jitsiConference.getParticipants();
            users.forEach(u => {
                if (u._id === id){
                    name = u._displayName;
                }
            })
        }



        const tile = new Tile ({ 
            target: tiles,
            props: {
                id: id,
                name: name,
            }
        })

    }

    //(1000 * 60 * 1)
    onMount(() => {

        //set the icons to muted if we start with disabled devices
        if (!get(localTracks).camera){
            cameraMuted = true;
            DevicesInterface.muted.camera = true;
        }
            

        if (!get(localTracks).mic){
            micMuted = true;
            DevicesInterface.muted.mic = true;
        }
            

        if (userType == 'eng'){
            sessionClockLoop();
        }

        if (window.screen.height <= 900)
            screenWidth = "63vw";


        setSpinner('closed')
        session.style.visibility = 'visible';
        hasLoaded = true;

        checkEventQueue(get(meetEvents).events);
        // meetEventsCleanInterval = setInterval(() => {
        //     meetEvents.cleanup()
        // }, 4000);
        

    })

    onDestroy(() => {
        meetEventsUnsub();
        clearInterval(meetEventsCleanInterval);
    })

</script>

    {#if userType === 'eng' && OutputConfig.initInfo.session.auth.auth_type === 'session'}
        <div id='uiClockWrapper'>
            <p id='uiClock'>{uiRemain}</p>
        </div>
    {/if}

    <div bind:this={session} id='session'>

        <div id='leftWrapper'>

            <div id='upperThird'>

                <div id='screen'style="--screen-width: {screenWidth}">
                    <audio id='displayAudio'/>
                    <canvas id='displayCanvas'/>
                </div>

                <div id='tray'>            
                    
                    <!-- <div id = 'invisibleTray'/> -->

                    <div id='leftTray'>
                        
                        {#if !cameraSpinner}
                            <!-- svelte-ignore a11y-click-events-have-key-events -->
                            <!-- svelte-ignore a11y-no-static-element-interactions -->
                            <div class='iconWrapper'>
                                <div class="hitbox"
                                    on:mouseenter= {() => {showMenus('camera')}}
                                    on:focus = {() => {showMenus('camera')}}
                                    on:mouseleave = {() => {hideMenus('camera')}}
                                    on:blur = {() => {hideMenus('camera')}}/>
                            
                            
                                <i class="fa-thin {vid_mute_state}"
                                    on:click={() => toggleMutes('camera')}
                                    on:mouseenter= {() => showMenus('camera')}
                                    on:focus = {() => showMenus('camera')}
                                    on:mouseleave = {() => hideMenus('camera')}
                                    on:blur = {() => hideMenus('camera')}>    
                                    {#if menuState.camera}
                                        <DeviceMenu type={'camera'} deviceMuted = {cameraMuted}/>
                                    {/if}
                                </i>
                            </div>

                        {:else}
                            <i class="fa-thin fa-spinner-third fa-spin"></i>
                        {/if}

                        <!-- svelte-ignore a11y-click-events-have-key-events -->
                        <!-- svelte-ignore a11y-no-static-element-interactions -->
                        <div class='iconWrapper'>
                            <div class="hitbox"
                                on:mouseenter= {() => {showMenus('mic')}}
                                on:focus = {() => {showMenus('mic')}}
                                on:mouseleave = {() => {hideMenus('mic')}}
                                on:blur = {() => {hideMenus('mic')}}/>
                        
                        
                            <i class="fa-thin {mic_mute_state}"
                                on:click={() => toggleMutes('mic')} 
                                on:mouseenter= {() => {showMenus('mic')}}
                                on:focus = {() => {showMenus('mic')}}
                                on:mouseleave = {() => {hideMenus('mic')}}
                                on:blur = {() => {hideMenus('mic')}} >
                                {#if menuState.mic}
                                    <DeviceMenu type={'mic'} deviceMuted = {micMuted}/>
                                {/if}

                                <!-- <DeviceMenu type={'mic'} deviceMuted = {micMuted}/> -->
                            </i>
                        </div>

                        <!-- svelte-ignore a11y-click-events-have-key-events -->
                        <!-- svelte-ignore a11y-no-static-element-interactions -->
                        <div class='iconWrapper'>
                            <div class="hitbox"
                                on:mouseenter= {() => {showMenus('speaker')}}
                                on:focus = {() => {showMenus('speaker')}}
                                on:mouseleave = {() => {hideMenus('speaker')}}
                                on:blur = {() => {hideMenus('speaker')}}/>
                            

                            <i class="fa-thin {speaker_mute_state}" 
                                on:click={() => toggleMutes('speaker')}
                                on:mouseenter= {() => {showMenus('speaker')}}
                                on:focus = {() => {showMenus('speaker')}}
                                on:mouseleave = {() => {hideMenus('speaker')}}
                                on:blur = {() => {hideMenus('speaker')}}>
                                {#if menuState.speaker}
                                    <DeviceMenu type={'speaker'} deviceMuted = {speakerMuted}/>
                                {/if}
                                <!-- <DeviceMenu type={'speaker'} deviceMuted = {speakerMuted}/> -->
                            
                            </i>                            
                        </div>
                        
                        
                        
                        {#if userType === 'eng'}

                            <!-- svelte-ignore a11y-click-events-have-key-events -->
                            <!-- svelte-ignore a11y-no-static-element-interactions -->
                            <div class='iconWrapper'>
                                <div class="hitbox"
                                    on:mouseenter= {() => {showMenus('stream')}}
                                    on:focus = {() => {showMenus('stream')}}
                                    on:mouseleave = {() => {hideMenus('stream')}}
                                    on:blur = {() => {hideMenus('stream')}}/>
                            
                            
                                <i class="fa-thin {stream_mute_state}"
                                    on:click={() => toggleMutes('stream')} 
                                    on:mouseenter= {() => {showMenus('stream')}}
                                    on:focus = {() => {showMenus('stream')}}
                                    on:mouseleave = {() => {hideMenus('stream')}}
                                    on:blur = {() => {hideMenus('stream')}}>
                                    {#if menuState.stream}
                                        <DeviceMenu type={'stream'} deviceMuted = {streamMuted}/>
                                    {/if}
                                </i>

                            </div>

                        {/if}
                    
                        <div style="position: relative">
                            {#if $chatMessages.unread > 0}
                                <span id="unreadBadge"/>
                            {/if}
                            <i on:click={toggleChat} class="fa-thin fa-comments"/>
                        </div>
                    </div>
                    
                    <!-- <div id='rightTray'>
                        <i on:click={toggleChat} class="fa-thin fa-comments"></i>
                    </div> -->

                </div>

            </div>

            <div bind:this={tiles} id='tiles' style="--justify-tiles: {justifyTiles}"/>
          
        </div>

        {#if chatOpen}
            <div id='rightWrapper'>
                <Chat/>
            </div>
        {/if}
        
    </div>

   

<style>

    .hitbox{

        z-index: 50;
        position: absolute;
        cursor: pointer;
        width: 100%;
        height: 200%;
        top: -20px;
    }

    .iconWrapper{
        position: relative;
        text-align: center;
        width: 100%;
        height: 100%;
    }


    #uiClockWrapper {
        position: absolute;
        top: -1px;
        left: 15px;
    }

    #uiClock {
        color: bisque;
    }

    #session {
        
        visibility: hidden;

        /* display: grid;
        grid-template-columns: repeat(2, min-content); */

        display: flex;
        column-gap: 3em;
        align-items: flex-start;
        justify-content: center;

        margin: 2em 2em 0px 2em;
    }

    #leftWrapper {
        display: grid;
        grid-template-columns: min-content;
    }

    @media (max-width: 1000px){
        #rightWrapper {
            position: absolute;
            right: 2em;
            width: 350px;
            z-index: 100;
        }
    }

    #rightWrapper {
        
        flex-grow: 1;
        display: grid;
        justify-items: center;

        /* margin-left: 4em;
        margin-right: 10px; */
        height: 85vh;
    }

    #screen {
        justify-self: center;
        aspect-ratio: 16 / 9;
        width: var(--screen-width);
    }

    #displayCanvas {
        border: 0.5px solid rgba(109, 109, 109, 0.732);
        border-radius: 8px;
        height: 100%;
        aspect-ratio: 16 / 9;
        background-color: black;
    }

    #upperThird {
        display: grid;
    }

    #tray {
        
        display: flex;
        justify-content: center;
        /* justify-content: space-between; */

        /* color: rgb(252, 249, 246); */
        color: #ffdb97ab;
        font-size: 1.5em;
    
        margin-top: 0.5em;
        margin-bottom: 0.5em;

    }

    #leftTray {
        
        /* display: flex; */
        display: grid;
        align-items: center;
        justify-items: center;
        grid-auto-flow: column;
        grid-auto-columns: 55px;
        
        background: #2e2e2e66;
        border-radius: 20px;
        padding: 0.25em;
        /* margin-left: 0.5em; */
    }


    #unreadBadge {
        
        position: absolute;

        display: flex;
        justify-content: center;
        align-items: center;

        left: 48%;

        width: 6px;
        height: 6px;

        border-radius: 50%;

        background: #ff0000a6;
        animation-duration: 2s;
        animation-name: pulse-red;
        animation-iteration-count: infinite;
        
    }

    @keyframes pulse-red {
		
		0% {
			transform: scale(0.97);
			box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
		}

		70% {
			transform: scale(1);
			box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
		}

		100% {
			transform: scale(0.97);
			box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
		}
	
	}

    /* #rightTray {
        display: flex;
        background: #2e2e2e66;
        border-radius: 20px;
        padding: 0.25em;
    } */

    #tiles {

        /* display: flex;
        flex-wrap: wrap;
        justify-content: var(--justify-tiles); */

        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-auto-flow: row dense;
        
        width: 100%;
        
        row-gap: 0.8em;
        column-gap: 0.7em;
    }

    @media (max-width: 1100px){
        #tiles {
            grid-template-columns: repeat(3, 1fr);
        }
    }

    /* @media (max-width: 750px){
        #tiles {
            grid-template-columns: repeat(2, 1fr);
        }
    } */

    .fa-thin {
        /* margin-right: 0.5em;
        margin-left: 0.5em; */
        position: relative;
        z-index: 100;
        margin: auto;
        cursor: pointer;
    }

    .fa-spinner-third {
        cursor: default;
    }

    .fa-microphone :global(.micDeviceMenu) {
        display: none;
        background-color: blue;
    }
    
    .fa-microphone-slash, .fa-video-slash, .fa-volume-slash, .fa-signal-stream-slash {
        color: rgba(254, 3, 3, 0.724);
    }


</style>