
import { xsysApi } from "./z-xsysApi.js";
import { uuidv4 } from "./utils.js";
import { BuildSettings, OutputConfig } from "./output-config.js";
//import { Logger } from "./Logger.js";

export const WebRTCConnector = {
    
    peerObjs: {}, //{xirsysID: [peerConnection, dataChannel, needsSream, {ping: Date.now(), pong: Date.now()}]} 
    iceCandidates: null,
    xsys_ws: null,
    channel: null,
    creds: null,
    userType: null,
    usage: 0,

    pingInterval: null,
    PING_INTERVAL_TIME: 1000,
    PONG_WAIT_THRESHOLD: 400, //callers who respond later than this count as a missed pong 
    missedPongs: 0,
    MAX_MISSED_PONGS: 3,

    //isRetrygSignaling: false,
    
    needsVStream: false,
    sendVStream: false,

    decoderThread: null, //passed as reference. This is for making vStream comms work
    dispatcher: null,

    /**
     * 
     * @param {*} bytes ArrayBuffer, bytes to send
     * @param {*} to 0 = all, 1 = needVStream peers, 2 = !needVStream peers
     */
    transmitBytes(bytes, to = 0){

        for (const peer in this.peerObjs){
            if (peer !== OutputConfig.user){
                let dataChannel_xsys = this.peerObjs[peer][1];        
                if (dataChannel_xsys && dataChannel_xsys.readyState === 'open'){
                    
                    switch(to){
                        case 0:
                            DefaultSend.call(dataChannel_xsys, bytes);
                            break;
                        case 1:
                            this.peerObjs[peer][2] && DefaultSend.call(dataChannel_xsys, bytes);
                            break;
                        case 2:
                            
                            !this.peerObjs[peer][2] && DefaultSend.call(dataChannel_xsys, bytes);
                            break;
                    }       

                } 
                
            }
        }
    },

    transmitString(object){

        if (object.needsVStream === true || object.needsVStream === false){
            this.needsVStream = object.needsVStream;
            const engKey = Object.keys(this.peerObjs).filter(poKey => poKey.includes('eng'))[0];
            const eng = this.peerObjs[engKey];
            if (eng && eng[1] && eng[1].readyState === 'open')
                DefaultSend.call(eng[1], JSON.stringify(object))
        }

        else if (object.outputConfig || object.transport){
            for (const peer in this.peerObjs){
                let dataChannel_xsys = this.peerObjs[peer][1]
                if (dataChannel_xsys && dataChannel_xsys.readyState === 'open'){
                    DefaultSend.call(dataChannel_xsys, JSON.stringify(object));
                }    
            }
        }    
    },


    transmitNeedsVStrem(object){

        this.needsVStream = object.needsVStream; //update this version of our state

        for (const peer in this.peerObjs){    
            if (peer.includes('eng')){
                let msg = JSON.stringify(object);
                let dataChannel_xsys = this.peerObjs[peer][1]
                if (dataChannel_xsys && dataChannel_xsys.readyState === 'open')
                    DefaultSend.call(dataChannel_xsys, msg);
                    
            }
        }

    },


    transmitTalentStream(bytes){
        
        for (const peer in this.peerObjs){
        
            if (peer !== OutputConfig.user){

                try {
                    
                    const dataChannel_xsys = this.peerObjs[peer][1] 
                
                    if (!dataChannel_xsys || dataChannel_xsys.readyState !== 'open'){
                        //console.warn('Peer channel is not open')
                        continue;
                    }
                        
                    //send to everyone during playback only (this is not the default)
                    if (BuildSettings.talentPlaybackToggle && OutputConfig.isPlaying){
                        DefaultSend.call(dataChannel_xsys, bytes);
                    }
                        
                    //or send to only eng all the time
                    else if (peer.includes('eng')){
                        DefaultSend.call(dataChannel_xsys, bytes);
                    }
                }

                catch (e) {
                    console.warn('dataChannel_xsys not available', e)
                }

            } 
            
        }

    },


    disconnect(){
        
        console.log('[][] Cleaning Up / WebRTC Disconnect');

        for (const obj in this.peerObjs){
            this.peerObjs[obj][0].close();
            delete this.peerObjs[obj];
        }
        
        if (this.xsys_ws){
            this.xsys_ws.close(); 
            this.xsys_ws = null;
        }

    },

     async resetSignaling() {

        if (this.isRetryingSignaling){
            console.log('Retry Already Started')
            return
        }
            
        //console.log('[+Starting Signaling Retry Function]+');

        this.missedPongs = 0;
        this.isRetryingSignaling = false;
        
        return new Promise(async (resolve, reject) => {

            const delay = (ms) => new Promise((resolve) => setTimeout(() => resolve(), ms))
            let success = false;
            let retry = true;
            let retryCount = 0;

            while (retry){
                
                try {
                    
                    this.disconnect();
                    await this.connect(this.channel, this.creds, this.userType);
                    success = true;
                    retry = false;
                } 
                
                catch (e){
                    
                    console.log('Signaling Connect Failed: ', e);

                    if (retryCount > 100){
                        retry = false;
                        success = false;
                    }
                        
                    await delay(5000);
                    retryCount++
    
                    console.log('Retrying Signaling');
                    
                }
            }

            this.isRetryingSignaling = false;
            success ? resolve('Signaling Ok') : reject('Signaling Retry Maxed');

        })

    },

    //** Revise this formatting **//
    // async getStats(){
        
    //     const data = [];

    //     for (const id in this.peerObjs){
            
    //         const peerStats = await this.peerObjs[id][0].getStats(null);
    //         const filtered = [];
    //         peerStats.forEach(report => {
    //             if (report.type === 'peer-connection' || report.type === 'transport' || report.type === 'data-channel' || report.type === 'candidate-pair')
    //                 filtered.push(report);
    //         })

    //         data.push({
    //             peerId: id,
    //             peerStats: filtered
    //         })

    //     }

    //     return {
    //         type: 'webrtc',
    //         data: data
    //     }

    // },

    cancelPingInterval(){

        if (this.pingInterval){
            console.log('Canceling Ping Interval');
            clearInterval(this.pingInterval);
            this.pingInterval = null;
        }
            
        for (const peer in this.peerObjs){
            if (peer.includes('eng') || peer.includes('talent')){
                this.peerObjs[peer][3].ping = 0;
                this.peerObjs[peer][3].pong = 0;
            }
        }
    },

    /**
     * There is a single ping interval which pings and keeps track of responses for each pingable peer.
     * The interval checks only the peers that we have in the peerObjs dictionary
     * As it is, this starts the interval even if no pingable clients exist, though in that case, no pings are sent.
     */
    startPingInterval(){

        if (!this.pingInterval && (OutputConfig.initInfo.urlResult.type === 'client' || OutputConfig.initInfo.urlResult.type === 'talent')){ 
            console.log('Eng and/or Talent Peer - Setting Up Ping Interval');
            this.pingInterval = setInterval(this.pingCallers.bind(this), this.PING_INTERVAL_TIME)
        }
            
    },

    async pingCallers(){
        
        try {

            for (const peer in this.peerObjs){
            
                if (peer.includes('eng') || peer.includes('talent')){ //we may have rtc objects from ghost peers, they are not active, but they may be in the peerObjects collection

                    if (Math.abs(this.peerObjs[peer][3].pong - this.peerObjs[peer][3].ping) > this.PONG_WAIT_THRESHOLD){
                        
                        if (this.missedPongs >= this.MAX_MISSED_PONGS){
                            console.log('Max Pongs Missed');
                            throw new Error('Max Pongs Missed');
                        }
                        
                        else {
                            this.missedPongs++;
                            console.log('Missed A Pong, total missed: ', this.missedPongs);
                        }
                            
                    }
                                    
                    let dataChannel_xsys = this.peerObjs[peer][1];
                    
                    if (dataChannel_xsys && dataChannel_xsys.readyState === 'open'){ //without this we might be sending to ghost peers here, failing and then trigerring a restart incorrectly (inside the catch block)
                        DefaultSend.call(dataChannel_xsys, 'ping');
                        //dataChannel_xsys.send('ping');
                        const pingTime = Date.now();
                        this.peerObjs[peer][3].ping = pingTime;
                    }
                    
                    //console.log('Sent Ping: ', pingTime);
                }
            }

        }

        catch (e){
        
            console.log('--- Ping Fail: ', e);  
            const result = await this.resetSignaling();
            console.log('Reset Signaling Finished from Ping: ', result);

        }
    },

    //eng does this with everyone -- talent does this with clients
    async generatePeerAndOffer(iceCandidates, peer, channel, user, xsys_ws){

        let ic = iceCandidates;
        if (iceCandidates.constructor !== Array){
            ic = [];
            for (const key in iceCandidates){
                ic.push(iceCandidates[key]);
            }
        }

        let peerConnection = new RTCPeerConnection({iceServers: ic});

        // peerConnection.onicecandidateerror = (event) => {
        //     console.error('ICE candidate error:', event.errorCode, event.errorText);
        // };
          
        peerConnection.onconnectionstatechange = async e => {
            const state = peerConnection.connectionState;
            if (state === 'closed' || state === 'failed'){
                
                console.log('--- peerConnection: ', state);
                //const result = await this.resetSignaling();
                //console.log('Reset Signaling Finished from peerConnection: ', result);

            }
        };

        peerConnection.onicecandidate = candidate => {
            
            if (!candidate.candidate) return;
            if (BuildSettings.forceTURN && candidate.candidate.type !== 'relay') return;

            const cPkt = {type: "candidate", sdpMLineIndex: candidate.candidate.sdpMLineIndex, sdpMid: candidate.candidate.sdpMid, candidate: candidate.candidate};
            const iceMsg = {t: "u", m: {f: channel + '/' + user, t: peer, o: 'message'}, p: {msg:cPkt}}; 

            //console.log('* Sending ICE Candidate : ', iceMsg, ' To Peer: ', peer);
            xsys_ws.send(JSON.stringify(iceMsg))
            
        };
        
        let dataChannel_xsys = peerConnection.createDataChannel(JSON.stringify({
            origin: user,
            target: peer,
        }));
        
        dataChannel_xsys.binaryType = 'arraybuffer';

        let offer = await peerConnection.createOffer();
        
        peerConnection.setLocalDescription(offer); //the result is an SDP object - session description
        
        let offerMsg = { t: "u", m: {f: channel + "/" + user, t: peer, o: "message"}, p: {msg: offer}};
        
        //console.log('* Sending Offer : ', offerMsg, ' To Peer: ', peer);

        xsys_ws.send(JSON.stringify(offerMsg));
        
        dataChannel_xsys.onmessage = m => {

            let msg = null;
            const label = JSON.parse(m.target.label); //this is a JSON object that describes originator id and receiver id for each data channel
            const target = label.target;
            const origin = label.origin;
            
            switch(OutputConfig.initInfo.urlResult.type) 
            {

                case 'eng':

                    if (typeof m.data === 'string'){
                        
                        if (m.data === 'ping'){
                            
                            if (dataChannel_xsys.readyState === 'open')
                                DefaultSend.call(dataChannel_xsys, 'pong');
                                //dataChannel_xsys.send('pong');   
                            return;
                        }
                            
                        msg = JSON.parse(m.data);
                    
                        if (msg.clientHelloWorld){ 

                            if (msg.needsVStream === true || msg.needsVStream === false){
                                this.peerObjs[target][2] = msg.needsVStream;
                                //shouldnt we trigger a streamNeed check here?
                                console.log(target + ' @@ stream need: ', this.peerObjs[target][2]);
                            }

                            if (OutputConfig.isPlaying)
                                DefaultSend.call(dataChannel_xsys, JSON.stringify({transport: 'playing'}));

                            if (OutputConfig.outputConfig.sampleRate)
                                DefaultSend.call(dataChannel_xsys, JSON.stringify({outputConfig: OutputConfig.outputConfig}));
                                
                            console.log('*** Eng fully connected to', target);
                        }

                        //update stream need for the relevant client
                        //then check if we still need transcoding at all
                        else if (msg.needsVStream === true || msg.needsVStream === false){
                            
                            this.peerObjs[target][2] = msg.needsVStream;
                            
                            //when any peer individually updates their needsVStream, we check if any still need the stream. If not, we stop encoding the additional frames completely
                            for (const peer in this.peerObjs){
                                if (this.peerObjs[peer][2] === true){
                                    this.sendVStream = true;
                                    this.decoderThread.postMessage({sendVStream: true});
                                    console.log(target + ' @@ stream need: ', this.peerObjs[target][2]);
                                    return;
                                }
                                
                            }

                            this.sendVStream = false; //our own copy of this bool
                            console.log(`[SEND vStream]: ${this.sendVStream}`);
                            this.decoderThread.postMessage({sendVStream: false})
                            
                        }
                    }

                    else {

                        msg = {talentStream: m.data}
                        this.dispatcher.Route(msg, 'talentStream')
                    }


                break;

                case 'talent':

                    if (typeof m.data === 'string'){

                        if (m.data === 'ping'){
                            
                            if (dataChannel_xsys.readyState === 'open'){
                                DefaultSend.call(dataChannel_xsys, 'pong');
                                //dataChannel_xsys.send('pong');
                            }
                                
                            
                            return;
                        }

                        else if (m.data === 'pong'){

                            const time = Date.now()
                            this.peerObjs[origin][3].pong = time;
                            this.missedPongs = 0;
                            //console.log('Received Pong: ', time);
                            return;
                        }

                        msg = JSON.parse(m.data)
                        
                        if (msg.clientHelloWorld){    
                            console.log('*** Talent fully connected to', target);
                        }
                        
                    }

                    else {

                        //talent gets playback
                        msg = {stream: m.data} 
                        this.dispatcher.Route(msg, 'stream')
                    }
                    

                break;

            }

        };

        return [peerConnection, dataChannel_xsys, false, {ping: 0, pong: 0}] 
    },

    // ** For Callees ** // 
    //clients are always callees, talent is a callee relative to eng and a caller relative to clients
    //all callees ping the eng and talent
    generatePeerObjectAfterOffer(iceCandidates, peer, channel, user, xsys_ws){

        let ic = iceCandidates;
        if (iceCandidates.constructor !== Array){
            ic = [];
            for (const key in iceCandidates){
                ic.push(iceCandidates[key]);
            }
        }

        let peerConnection = new RTCPeerConnection({iceServers: ic});
        let dataChannel_xsys = peer; //this is a placeholder, it's our webrtc_id
        
        peerConnection.onconnectionstatechange = async e => {
            const state = peerConnection.connectionState;
            if (state === 'closed' || state === 'failed'){
                
                console.log('--- peerConnection : ', state);
                const result = await this.resetSignaling();
                //console.log('Reset Signaling Finished from peerConnection: ', result);

            }
        }

        // peerConnection.onicecandidateerror = (event) => {
        //     console.error('ICE candidate error:', event.errorCode, event.errorText);
        //   };
          
        peerConnection.onicecandidate = candidate => {
        
            if (!candidate.candidate) return;
            if (BuildSettings.forceTURN && candidate.candidate.type !== 'relay') return;

            const cPkt = {type: "candidate", sdpMLineIndex: candidate.candidate.sdpMLineIndex, sdpMid: candidate.candidate.sdpMid, candidate: candidate.candidate};
            const iceMsg = {t: "u", m: {f: channel + '/' + user, t: peer, o: 'message'}, p: {msg:cPkt}}; 

            //console.log('* Sending ICE Candidate : ', iceMsg, ' To Peer: ', peer);
            xsys_ws.send(JSON.stringify(iceMsg))
            
        }

        peerConnection.ondatachannel = async e => {
            
            dataChannel_xsys = e.channel;
            DefaultSend.call(dataChannel_xsys, JSON.stringify({clientHelloWorld: true, needsVStream: this.needsVStream}));
            console.log(`*** Callee ${OutputConfig.user} has data channel open`);
            
            //Remove the placeholder from the datachannel slot
            for (const peer_id in this.peerObjs){
                
                if (this.peerObjs[peer_id][1] === peer){
                    this.peerObjs[peer_id][1] = dataChannel_xsys;
                    // if (!this.pingInterval){
                    this.startPingInterval(); //checks if we already have one
                    //}  
                }
            }
            
            dataChannel_xsys.onmessage = m => {
                
                let msg = null;
                
                if (typeof m.data === 'string'){ 

                    const label = JSON.parse(m.target.label);
                    const origin = label.origin;

                    if (m.data === 'pong'){
                        const time = Date.now()
                        this.peerObjs[origin][3].pong = time;
                        this.missedPongs = 0;
                        //console.log('Received Pong: ', time);
                        return;
                    }

                    
                    msg = JSON.parse(m.data)
                    if (msg.outputConfig) 
                        msg.outputConfig.type = 'client';

                }
                
                
                else { msg = {stream: m.data} }
                this.dispatcher.Route(msg, 'stream')

            }

        } 
        
        return [peerConnection, dataChannel_xsys, null, {ping: 0, pong: 0}]

    },

    async generateAnswer(peer, channel, user, xsys_ws, offer, peerObject){

        let offerDescrip = new RTCSessionDescription(offer)
        peerObject.setRemoteDescription(offerDescrip)
        peerObject.createAnswer()
        .then(answer => {
            peerObject.setLocalDescription(answer)
            let answerMsg = { t: "u", m: {f: channel + "/" + user, t: peer, o: "message"}, p: {msg: answer}};
            xsys_ws.send(JSON.stringify(answerMsg))
            //console.log('* Sent answer to: ', peer)
        })
        .catch( error => console.error(error))

    },
    
    
    //const result = await xsysApi.getConnectedUsers(channel, creds);
    //if (result.s === "ok"){
    //const currentUsers = result.v;
    async removeEngUsers(channel, creds, users, exlcude){  
        for (const u of users){
            if (u.includes("eng") && !exlcude.includes(u)){
                const result = await xsysApi.removeUser(channel, creds, u);
            }
        }
    },

    async connect(channel, creds, userType){

        let user = null;

        if (userType === 'eng' || userType === 'talent') {  
            user = userType + '_' + uuidv4();
        }

        else {user = uuidv4()};
        OutputConfig.user = user;


        this.iceCandidates = await xsysApi.genereateIceObjects(channel, creds);
        this.xsys_ws = await xsysApi.connectToSignaling(channel, user, creds);
        //Logger.addCallback('webrtc', this.getStats.bind(this)); 
        
        this.xsys_ws.onmessage = async e => {
            
            let data = JSON.parse(e.data);
            let messageType = data.m.o;
            let peer;
            messageType === 'message' ? peer = data.m.f.split('/')[2] : peer = data.p

            //When a new peer is connected -- as long as that peer is not ourselves, we set up a new connection object for that peer
            switch(messageType){
                
                case "peer_connected":
                    
                    console.log("* Peer Connected: ", peer)
                    
                    /**
                     * Create a peer object for a new peer
                     */
                    if (peer !== OutputConfig.user && (userType === 'eng' || userType === 'talent')){ 
                        
                        if ((userType === 'talent' || userType === 'eng') && peer.includes('eng')){return} //talent only calls clients, eng should not call another eng (sometimes peers linger on the signaling server)
                        if (peer in this.peerObjs){return} //skip if we alrady have a connection. Retry creates a new id for the same peer
                        
                        console.log("* Creating peer object for: ", peer);
                        
                        this.peerObjs[peer] = await this.generatePeerAndOffer(this.iceCandidates, peer, channel, user, this.xsys_ws); //Generate PeerObject and do negotiation, then add to dict. --> [peerConnection, dataChannel, iceMessages?].
                    }
                    
                    break;

                case "peer_removed":
                    
                    console.log("* Peer Removed: ", peer);
                    if (this.peerObjs[peer]){
                        this.peerObjs[peer][0].close();
                        delete this.peerObjs[peer];
                    }

                    break;


                case "peers":
                    
                    console.log("*** Peers ***", data.p.users)
                    
                    /**
                     * Create peer objects for all of those that are already on
                     */
                    if (userType === 'eng' || userType === 'talent'){ 
                        
                        const peers = data.p.users;
                        for (let i = 0; i < peers.length; i++){

                            const peer = peers[i];

                            //skip if this is ourselves
                            if (peer === OutputConfig.user)
                                continue;
                            
                            //eng calls talent, eng should not call any other stray eng peers still hanging on to signaling
                            if ((userType === 'talent' || userType === 'eng') && peer.includes('eng'))
                                continue;
                            
                            //skip if we alrady have this connection. (Maybe we disable this?)
                            if (peer in this.peerObjs)
                                continue;
                            
                            console.log("* Creating peer object for: ", peer);

                            this.peerObjs[peer] = await this.generatePeerAndOffer(this.iceCandidates, peer, channel, user, this.xsys_ws); 

                        }

                    }

                    break;

                case "message":
                
                    switch (data.p.msg.type){
                        
                        case "answer":
                            
                            //if (user.slice(0, 3) === 'eng'){
                            //console.log('* Answer Received: ', peer)
                            let answerDesc = new RTCSessionDescription(data.p.msg)
                            await this.peerObjs[peer][0].setRemoteDescription(answerDesc)
                            break;

                        case "candidate":
                            
                            let candidate = new RTCIceCandidate(data.p.msg.candidate)
                            if (this.peerObjs[peer]){
                                //console.log('*** Setting ICE Candidate ***')
                                await this.peerObjs[peer][0].addIceCandidate(candidate)
                            }
                            
                            else {
                                console.log('*** No Peer object to add ICE to **** ', user )
                            }

                            break;

                        case "offer":
                            
                            //console.log("* Got offer from: ", peer)
                            if (peer in this.peerObjs === false){
                                
                                console.log("* Generating Peer After Offer From: ", peer)
                                this.peerObjs[peer] = this.generatePeerObjectAfterOffer(this.iceCandidates, peer, channel, user, this.xsys_ws)
                            }
                            await this.generateAnswer(peer, channel, user, this.xsys_ws, data.p.msg, this.peerObjs[peer][0])
                            break;
                        
                        default:
                            //console.log(data)
                            break;
                    }
            }
        }

    },


    async init(urlResult, rtcCreds, Dispatcher){

        const userType = urlResult.type
        let channel = rtcCreds.root + '/' + urlResult.roomName
        let creds = rtcCreds.key
        //let user = null;
        
        this.channel = channel;
        this.creds = creds;
        this.dispatcher = Dispatcher;
        this.userType = userType;

        await this.connect(this.channel, this.creds, this.userType);
    
    },

};




 //pingRetries: 0,
    //MAX_PING_RETIRIES: 1,
    //RETRY_DELAY_TIME: 200,
  
   // console.log('Retries: ', this.pingRetries);
            
            // await this._delay(this.RETRY_DELAY_TIME);
            
            // if (this.pingRetries < this.MAX_PING_RETIRIES){
                
            //     console.log('Retrying');
            //     this.pingRetries++
            //     await this.pingCallers();
            
            // }

            // else {

            //     console.log('Restarting Connection');
            //     clearInterval(this.pingInterval);
            //     this.pingInterval = null;
            //     this.disconnect();
            //     await this.connect(this.channel, OutputConfig.user, this.creds, this.userType);

            // }
    


/* OTHER FUNCTIONS 


async xirsys_listChannels(creds){
    let endPoint = 'https://global.xirsys.net/_ns/?depth=10'
    let headers = new Headers()
    headers.append("Authorization", "Basic " + btoa(creds))
    const params = {
        method: 'GET',
        headers: headers,
    }
    return await fetch(endPoint, params)

},


async xirsys_createChannelCall(creds, newChannelPath){
    let endPoint = 'https://global.xirsys.net/_ns/' + newChannelPath
    let headers = new Headers()
    headers.append("Authorization", "Basic " + btoa(creds))
    const params = {
        method: 'PUT',
        headers: headers,
    }
    return await fetch(endPoint, params)

},

async xirsys_deleteChannelCall(creds, channel){
    let endPoint = 'https://global.xirsys.net/_ns/' + channel
    let headers = new Headers()
    headers.append("Authorization", "Basic " + btoa(creds))
    const params = {
        method: 'DELETE',
        headers: headers,
    }
    return await fetch(endPoint, params)

},


async xirsys_resetDataKey(creds, channel){
    let outConfigKey = channel + '_outputConfig'
    let endPoint = 'https://global.xirsys.net/_data/' + channel + '?k=' + outConfigKey
    let headers = new Headers()
    headers.append("Authorization", "Basic " + btoa(creds))
    const params = {
        method: 'DELETE',
        headers: headers
    }
    
    return await fetch(endPoint, params)
},

async xirsys_createDataKey(creds, channel, outputConfig){
    let outConfigKey = channel + '_outputConfig'
    let endPoint = 'https://global.xirsys.net/_data/' + channel
    let headers = new Headers()
    headers.append("Authorization", "Basic " + btoa(creds))
    headers.append( "Content-Type", "application/json")
    const params = {
        method: 'PUT',
        headers: headers,   
        body: JSON.stringify({k: outConfigKey, v: outputConfig}) 
    }
    
    return await fetch(endPoint, params)

},

async xirsys_getOutputConfig(creds, channel){
    let outConfigKey = channel + '_outputConfig'
    let endPoint = 'https://global.xirsys.net/_data/' + channel + '?k=' + outConfigKey
    let headers = new Headers()
    headers.append("Authorization", "Basic " + btoa(creds))
    const params = {
        method: 'GET',
        headers: headers,
    }
    return await fetch(endPoint, params)

},

async xirsys_updateOutputConfig(creds, channel, version, outputConfig){
    let outConfigKey = channel + '_outputConfig'
    let endPoint = 'https://global.xirsys.net/_data/' + channel
    let headers = new Headers()
    headers.append("Authorization", "Basic " + btoa(creds))
    headers.append( "Content-Type", "application/json")
    outputConfig['_ver_'] = version
    
    const params = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({k: outConfigKey, v: outputConfig}) 
    }
    return await fetch(endPoint, params)

},
*/


   // if (peer.includes('eng') || peer.includes('talent')){

    //     const dataChannel = this.peerObjs[peer][1];
        
    //     if (dataChannel && this.pingTargets.includes(dataChannel.label)){

    //         console.log('Removing a ping target: ', peer, dataChannel.label);
    //         this.pingTargets.splice(this.pingTargets.indexOf(dataChannel.label), 1);
            
    //         if (this.pingTargets.length <= 0){ //maybe we dont need this?
    //             console.log('Last ping target removed, clearing interval: ', peer, dataChannel.label);
    //             clearInterval(this.pingInterval);
    //             this.pingInterval = null;
    //         }
                
    //     } 
    // }




    // transmitStream(bytes){

    //     for (const peer in this.peerObjs){
            
    //         if (peer !== OutputConfig.user){
    //             let dataChannel_xsys = this.peerObjs[peer][1]
    //             if (dataChannel_xsys && dataChannel_xsys.readyState === 'open' && !this.peerObjs[peer][2])
    //                 DefaultSend.call(dataChannel_xsys, bytes);
    //                 //dataChannel_xsys.send(bytes);
    //         }
            
    //     }
    // },


    // transmitVStream(bytes){

    //     for (const peer in this.peerObjs){
            
    //         if (peer !== OutputConfig.user){
                
    //             let dataChannel_xsys = this.peerObjs[peer][1]
    //             if (dataChannel_xsys && dataChannel_xsys.readyState === 'open' && this.peerObjs[peer][2] === true)
    //                 DefaultSend.call(dataChannel_xsys, bytes);
    //                 //dataChannel_xsys.send(bytes);
                
    //         }
    //     }
    // },

    // let msg = JSON.stringify(object);

    // for (const peer in this.peerObjs){    
    //     if (peer.includes('eng')){
    //         let dataChannel_xsys = this.peerObjs[peer][1]
    //         if (dataChannel_xsys && dataChannel_xsys.readyState === 'open')
    //             DefaultSend.call(dataChannel_xsys, msg);
    //             //dataChannel_xsys.send(msg) 
                
    //     }
    // }


    // transmit(object){
        
    //     let msg;
    
    //     if (object.outputConfig){
    //         msg = JSON.stringify(object)
    //         for (const peer in this.peerObjs){
    //             let dataChannel_xsys = this.peerObjs[peer][1]
    //             if (dataChannel_xsys && dataChannel_xsys.readyState === 'open'){
    //                 DefaultSend.call(dataChannel_xsys, msg);

    //                 //dataChannel_xsys.send(msg) 
    //             }    
    //         }
    //     }

    //     else if (object.transport)
    //     {
            
    //         msg = JSON.stringify(object);
    //         for (const peer in this.peerObjs){
    //             let dataChannel_xsys = this.peerObjs[peer][1]
    //             if (dataChannel_xsys && dataChannel_xsys.readyState === 'open'){ 
    //                 DefaultSend.call(dataChannel_xsys, msg);
    //                 //dataChannel_xsys.send(msg) 
    //             }    
    //         }

    //     }

    // },

