<script>

    import { CallApi } from './call-api.js'
    import { onMount, tick } from 'svelte';
	import { slide } from 'svelte/transition';
	import { quintOut } from 'svelte/easing';
	import { get } from 'svelte/store';
	import { chatMessages, screenName } from './stores.js';

	let messages = null;
	let newText = '';
	
	let isScrolledBottom = false;
	let m_height = "65vh";
	let formHeight = 60;
	let lastScrollTop = 0;

	const onSubmit = async () => {

		if (newText === '')
			return;

		let sn = get(screenName).trim(); 
		//sn === "Name?" ? sn = CallApi.jitsiConference.myUserId() : sn;

		const newMessage = {
			screenName: sn,
			text: linkifyStr(newText, { target: "_blank", className: "msgLink" })
		};

        const linkPos = newMessage.text.indexOf("<a");
        if (linkPos >= 0){
            newMessage.text = [newMessage.text.slice(0, linkPos + 2), ` style="color: #fdc6be" `, newMessage.text.slice(linkPos + 2)].join('');
        }

		sendMessage(newMessage);
		newMessage.local = true;

		renderMessage(newMessage);
		newText = '';
	
	}

	const sendMessage = (newMessage) => {
        CallApi.jitsiConference.sendTextMessage(JSON.stringify({to: 'all', from: CallApi.jitsiConference.myUserId(), payload: newMessage}))
	};

	const checkScroll = async () => {
		if (messages){
			const diff = Math.abs(messages.scrollHeight - messages.scrollTop);
			if (Math.abs(messages.clientHeight - diff) <= 5){
                isScrolledBottom = true;
                chatMessages.update(msgObject => {
                    msgObject.unread = 0;
                    return msgObject;
                })	
            }
						
		}
	}

	const updateScroll = async () => {
		await tick();
		if (messages && isScrolledBottom){
			messages.scrollTop = 4000000; //just a big number to force max scroll. Number.MAX_VALUE does not work
			lastScrollTop = messages.scrollTop;
		}
	}

    //this is called when we hit submit locally. Remote messages are added to the store and are rendered automatically
    //by that for loop inside of the svelte markup, which listens for changes
	const renderMessage = (newMessage) => {
		$chatMessages.msgs = [...$chatMessages.msgs, newMessage];
	}

    const onEnter = (e) => {
        if (e.keyCode === 13 && !e.shiftKey && !e.repeat){
            e.preventDefault();
            onSubmit();
        }
    };

    onMount(() => { 
		document.getElementById("txtArea").addEventListener("keydown", onEnter); 
		messages.addEventListener("scroll", e => {
			if (e.target.scrollTop < lastScrollTop)
				isScrolledBottom = false;
		});

        chatMessages.update(msgObject => {
            msgObject.unread = 0;
            return msgObject;
        })

        messages.addEventListener("scroll", (e) => {
            checkScroll();
        })
	})
	

</script>
        
    <div class="chat">
    
        <div class="messages" bind:this={messages} style="--c-height: {m_height}">
            {#each $chatMessages.msgs as message}
                <p transition:slide|local={{ easing: quintOut }} on:introstart={checkScroll} on:introend={updateScroll} class="{message.local ? 'message msg-local' : 'message msg-remote'}">
                    <span class="{message.local ? 'msg-name-local' : 'msg-name-remote'}">{message.screenName}</span>: {@html message.text}
                </p>
            {/each}
        </div>

        <form id="form" on:submit|preventDefault={onSubmit}>
            <div id="formWrapper">
                <textarea style="background-color: #f1f1f1e0" type="text" id="txtArea" placeholder="Type a message..." bind:value={newText}/>
                <button type="submit">
                    <i class="fa-thin fa-message-arrow-up-right"></i>
                </button>
            </div>
        </form>
        
    </div>

<style>


	.chat {

        align-self: center;

        display: flex;
        /* justify-content: center; */
        flex-direction: column;
        overflow: hidden;

        height: 98%;
        width: 98%;
        max-width: 98%;

        border: 0.5px solid rgba(109, 109, 109, 0.732);
        border-radius: 10px;
        
        font-size: 14px;
        font-weight: 250;

        box-shadow: 0px 0px 10px 0px #664f5ca6;
	}

	.messages {

		/* grid-row: 2/3; */
		display: flex;
		flex-direction: column;
        flex-grow: 0;

        overflow-x: hidden;
        overflow-y: scroll;
        height: 100%;
        width: 100%;

		/* padding-top: 0.7em; */
		/* height: var(--c-height); */
	}

    @media (max-width: 1000px){
        .messages{
            background: #1f1f1fd4;
        }
        .messages::-webkit-scrollbar-track{
            background: #1f1f1fd4;
        }
    }

    .messages::-webkit-scrollbar {
		background-color: rgb(10, 10, 10);
		/* background-color: #191919; */
        width: 10px;
	}

	.messages::-webkit-scrollbar-thumb {
        background-color: #d1d1d1d6;
        border: 4px solid transparent;
        border-radius: 10px;
        background-clip: padding-box;  
	}

	.message {

        max-width: 80%;

        margin-left: 0.7em;
        margin-right: 0.7em;
        margin-top: 0.5em;
        margin-bottom: 0.5em;
        padding: 0.7em;
        border-radius: 10px;

        overflow-wrap: break-word;
        /* white-space: pre-wrap; */
        white-space: normal;

        color: antiquewhite;
        /* background-color: #aaaaaa2b; */
        /* background-color: #e4adff1e; */

    }

	.msg-local {
		align-self: flex-start;
        background-color: #e4adff1e;
	}

	.msg-remote {
		align-self: flex-end;
        background-color: #b5adff1e;
	}

	.msg-name-local {
		color: #ff715bd9;
	}

	.msg-name-remote {
		color: #ff934fe6;

	}

    #formWrapper {

        display: flex;
		padding: 1em;
        border-radius: 9px;
    }

    #form {
        margin: 0.3em;
    }

	form button {
		
		background: transparent;
		border: none;
		cursor: pointer;
        color: wheat;
        font-size: 1.3em;
        padding-left: 1em;
	
	}

	#txtArea {

        width: 90%;
        /* height: 24px; */
        
        border-radius: 8px;

        font-family: 'Inter', sans-serif;
        font-size: 16px;
        font-weight: 350;

		overflow-y: scroll;
		resize: none;
	}

    #txtArea::-webkit-scrollbar {
        background-color: #d1d1d1d6;
		width: 10px;
	}

	#txtArea::-webkit-scrollbar-thumb {
    
        background-color: #191919;
        border: 4px solid transparent;
        border-radius: 10px;
        background-clip: padding-box;  
	}

	/* .new-notification {
		
		position: absolute;
		top: -10px;
        left: -10px;
		background: rgba(255, 82, 82, 0.513);
		box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.513);
		animation: pulse-red 2s infinite;
		border-radius: 50%;
		margin: 6px;
		height: 15px;
		width: 15px;
		transform: scale(1);

	} */

	

	/* @media only screen and (max-width: 900px)
	{
		.chat-container{
			position: absolute;
			background-color: rgb(10, 10, 10);
			z-index: 300;
			transition: right 0.3s ease-out;
			width: 35%;
		
		}

	} */

    /* .fa-messages{

        color: antiquewhite;
        font-size: 1.7em;
    }

	.chat-view-button {

		position: absolute;
		left: -59px;
		top: 0;
		bottom: 0;
		margin: auto 0;
		height: 56px;
        z-index: -200;
	}

	.chat-view-button button {
        
		background-color: rgb(10, 10, 10);
		border: none;
		cursor: pointer;
		border-radius: 16px 0 0 16px;
		padding: 16px 14px 13px 18px;

	} */


    	/* #closeButtonWrapper {
		grid-row: 1/2;
		padding: 0.25em;
		border-top: 0.5px solid rgba(109, 109, 109, 0.732);
		border-left: 0.5px solid rgba(109, 109, 109, 0.732);
		border-bottom: 0.5px solid rgba(109, 109, 109, 0.732);
		background-color: black;
	} */

	/* #closeButton {
		background-color: #12141d;
		border: none;
		cursor: pointer;
	}

	.fa-xmark{
		color: wheat;
		font-size: 1.8em;
	} */
	


	/* .chat-container {

		position: absolute;
		background-color: rgb(10, 10, 10);
		z-index: 300;
		transition: right 0.3s ease-out;
		width: 19%;
		right: calc(19% * -1);
		margin-top: 5px; 
	}

	.chat-open {
		right: 0;
	} */



</style>

