import { Lambdas } from './lambdas-api.js';
import { OutputConfig } from './output-config.js';
import { OutputInterface } from './output.js'
import { UI } from './ui.js';
import NotSupported from './NotSupported.svelte';
import { WebRTCConnector } from './z-webrtcConnector.js';
import { CallApi } from "./call-api.js";
import { DevicesInterface } from './devicesInterface.js';

import './styles.css'

const initModules = async (urlResult) => {

    UI.joinMeet(); //this is async, does not await
    UI.initCore(); //this blocks
    UI.loadStart(urlResult); //this blocks
    UI.initStreaming(); //this is async, does not await
}

window.onbeforeunload = e => {
    
    console.log('[][] Disconnecting Jitsi');
    CallApi.cleanupLocal();

    console.log('[][] Disconnecting Loopback webrtc');
    if (OutputInterface.localConnection) OutputInterface.localConnection.close();
    if (OutputInterface.loopbackConnection) OutputInterface.loopbackConnection.close();

    //close webrtc connection objects
    WebRTCConnector.disconnect();
}

//DOMContentLoaded
window.addEventListener('load', async e => {

    navigator.mediaDevices.addEventListener('devicechange', async e => { 
    
        //console.log("DEVICE CHANGE: --> ", e);
        try {
    
            if (OutputConfig.hasJoinedSession){
                await DevicesInterface.updateDeviceLists();
                await DevicesInterface.initFallbackDevices(); 
            }
        
            else {
                //TO DO:
                //update start panel devices menus
        
            }
        } catch (error){
            console.error(error);
        }
    
    })
    

    try {
        
        if (navigator.userAgent.indexOf("Chrome") === -1 || Number(navigator.userAgent.match(/.*Chrome\/([0-9\.]+)/)[1].split('.')[0]) < 110){
            const ns = new NotSupported({ target: document.getElementById('app') }) //move this to catch
            throw new Error('Only Desktop Chrome 110 or above is currently supported :(')
        }

        UI.setSpinner('open');
        //Logger.start();
        
        const allResults = await Lambdas.initWebClient();

        if (allResults.error)
            throw new Error('Init error - check the URL');
            
        OutputConfig.initInfo.urlResult = allResults.urlResult  //urlResult = {type: 'eng/client/talent', roomName: 'Room Name'}

        if (allResults.urlResult.type !== null && allResults.urlResult.roomName !== null){
            
            if (allResults.urlResult.type === 'eng'){
                const session = allResults.session //session: {'auth': authItems, 'ak': ak, 'sk': sk, 'sessionID': sessionID, 'bucket': bucket}
                if (!OutputConfig.isAuth(session)){
                    console.error('No Active Sessions');
                    //show a modal here with buy links
                }
                
                OutputConfig.initInfo.session = session
                //OutputConfig.outputConfig.session = {sessionID: session.sessionID, bucket: session.bucket, dlcreds: session.delcreds} //this gets overwritten by plugin
                initModules(OutputConfig.initInfo.urlResult) 
            }
            
            else
                initModules(OutputConfig.initInfo.urlResult)
        }

        else throw new Error("URL error or no such room")
        
    }

    catch(error) {
        UI.setSpinner('closed');
        console.error(error);
        return
    }
})
 
    


    
