import awpURL from 'omt:./awp.js'
import { OutputConfig, BuildSettings } from './output-config.js'
import {devices} from './stores.js'
import { get } from 'svelte/store'
//import { Logger } from './Logger.js'

export const OutputInterface = {

    audioContext: null,
    videoWorker: null,
    awp: null,
    
    decoderThread: null,
    videoThread: null,
    
    gainNode: null,
    outputOpen: false,

    audioOut: null,
    loopbackStream: null,
    localConnection: null,
    loopbackConnection: null,

    //videoQ: null,

    /**
     * 
     * This gets called only after joining conference (so we can set selected audio i/o)
     * 
     * @param {*} canvas --> canvas to be transferred to offscreen context 
     * @param {*} decoderThread --> reference to decoder thread to wire up message passing
     * @returns Promise
     */


    generateOutput(canvas, _decoderThread){
       
        return new Promise(async (resolve, reject) => {
         
            try {
                
                this.audioContext = new AudioContext({latencyHint: "playback", sampleRate: 48000}); 
                await this.audioContext.audioWorklet.addModule(awpURL); 
                this.awp = new AudioWorkletNode(this.audioContext, 'awp', {numberOfInputs: [1], numberOfOutputs: [2], outputChannelCount: [2, 2]});
                this.gainNode = new GainNode(this.audioContext);

                this.decoderThread = _decoderThread

                if (BuildSettings.enableLoopback){
                    //*** DO NOT USE */
                    // const streamNode = this.audioContext.createMediaStreamDestination();
                    // this.awp.connect(this.gainNode).connect(streamNode);                    
                    // this._generateLoopback(streamNode.stream); //setSpeaker is inside of loopback
                
                }

                else {

                    this.awp.connect(this.gainNode).connect(this.audioContext.destination);
                    this.audioOut = document.getElementById('displayAudio'); //dont need this here
                    let selector = 'speaker';
                    if (OutputConfig.initInfo.urlResult.type === 'eng') {
                        selector = 'stream';
                    }
                    
                    
                    const id = get(devices).selected[selector].id;
                    await this.setSpeakerId(id);
                    

                    //set reset the conference output here -- so that the aec awlays uses that as its ref output?
                    //this.setSpeaker(selector, BuildSettings.enableLoopback);
                }
                
                let workerCanvas = canvas.transferControlToOffscreen(); 
                this.decoderThread.postMessage(
                            {canvas: workerCanvas, awpPort: this.awp.port, vidConfig: OutputConfig.vidConfig}, 
                            [workerCanvas, this.awp.port]
                );
            
                this.outputOpen = true
                console.log('[Audio Output Created]');
                resolve(true)
            }

            catch(error){
                console.error(error)
                reject (false)
            }
            
       })         
        
    },

    setGain(shouldMute){
        let gain;
        shouldMute ? gain = 0 : gain = 1; 
        this.gainNode.gain.setValueAtTime(gain, this.audioContext.currentTime);
    },


    //maybe what we'd want to do is:
    /** 
    * For eng case --> this situation means we can't use two devices as a reference, so maybe whenever we change the audioContext sinkId, we again change the local audio tag sinkId so that that is always the AEC reference.
    * This is preferable since listening to stream is not usual (WE COULD ALSO SWITCH BACK AND FORTH DEPENDING ON MUTE STATE, LEVEL AND OTHER THINGS)   
    */
    async setSpeakerId(id){

        console.log('Setting Audio Context Sink');
        id === 'default' ? await this.audioContext.setSinkId('') : await this.audioContext.setSinkId(id);
        //console.log((this.audioContext.baseLatency + this.audioContext.outputLatency) * 1000);
        this.decoderThread.postMessage({sleepTime: (this.audioContext.baseLatency + this.audioContext.outputLatency) * 1000});
        OutputConfig.acSinkId = this.audioContext.sinkId;
    },


    //UNUSED - for volume control
    updateGain(gain){
        this.gainNode.gain.setValueAtTime(gain, this.audioContext.currentTime)
    },

}




    //Consider regenerating the audio context here in case we add a bunch of latency due to a new device
    // async setSpeaker(selector, enableLoopback = false){

    //     const selDevice = get(devices).selected[selector];
        
    //     if (!enableLoopback){
    //         let id = selDevice.id;
    //         if (id === 'default'){
    //             const allDevices = get(devices)[selector]; //note this is all devices for the given selector
    //             for (const d of allDevices){
    //                 if (d.groupId === selDevice.groupId && d.label !== selDevice.label){
    //                     id = d.deviceId;            
    //                 }
    //             }

    //         }
            
    //         await this.audioContext.setSinkId(id);

    //     }


    //     else {
            
    //         this.audioOut.setSinkId('default').then(() => this.audioOut.setSinkId(selDevice.id))
    //         this.audioOut.autoplay = true;
    //         this.audioOut.srcObject = this.loopbackStream
    //         this.audioOut.muted = false
    //     }


    
    //     //const latency = (this.audioContext.baseLatency + this.audioContext.outputLatency) * 1000;
    //     console.log((this.audioContext.baseLatency + this.audioContext.outputLatency) * 1000);
    //     this.decoderThread.postMessage({sleepTime: this.audioContext.outputLatency * 1000});

    // },


    // //MAY NEED TO REVIVE AS WORKAROUND?
    // async _generateLoopback(inputStream){
        
    //     let offerOptions = {
    //         offerVideo: false,
    //         offerAudio: true,
    //         offerToReceiveAudio: false,
    //         offerToReceiveVideo: false,
    //     }

    //     this.loopbackStream = new MediaStream()
    //     this.localConnection = new RTCPeerConnection()
    //     this.loopbackConnection = new RTCPeerConnection() //needs to be updated on a device change
        
    //     this.loopbackConnection.onicecandidate = e => {
    //         //console.log('loopback: ', e)
    //         e.candidate && this.localConnection.addIceCandidate(new RTCIceCandidate(e.candidate))
    //     }
    //     this.localConnection.onicecandidate = e => {
    //         //console.log('local: ', e)
    //         e.candidate && this.loopbackConnection.addIceCandidate(new RTCIceCandidate(e.candidate))
    //     }
    
    //     this.loopbackConnection.ontrack = async e => {

    //         e.streams[0].getTracks().forEach(async track => {
    //             if (track.kind === 'audio'){
                    
    //                 this.loopbackStream.addTrack(track)
    //                 this.audioOut = document.getElementById('displayAudio')
                    
    //                 //If this is eng, take the selection from the stream device menu instead of the speaker menu
    //                 let selector = 'speaker';
    //                 if (OutputConfig.initInfo.urlResult.type === 'eng') {
    //                     selector = 'stream';
    //                 }
                    
    //                 this.setSpeaker(selector, BuildSettings.enableLoopback);
    //             }  
                    
    //         });
    //     }

    //     this.localConnection.addStream(inputStream);

    //     let offer = await this.localConnection.createOffer(offerOptions);
    //     await this.localConnection.setLocalDescription(offer);
        
    //     await this.loopbackConnection.setRemoteDescription(offer);
    //     let answer = await this.loopbackConnection.createAnswer();
    //     answer.sdp = answer.sdp.replace('useinbandfec=1', 'useinbandfec=1; stereo=1; maxaveragebitrate=510000');
    //     await this.loopbackConnection.setLocalDescription(answer);
        
    //     await this.localConnection.setRemoteDescription(answer);


    // },


    // closeOutputs(){
    //     return new Promise (async (resolve, reject) => {
    //         try {
    //             //if (this.videoWorker){ this.videoWorker.terminate() }
    //             if (this.audioContext){ await this.audioContext.close() }
    //             this.outputOpen = false
    //             resolve(true)
    //         }

    //         catch(error){
    //             reject(error)
    //         }

    //     })
    // },