<script>

    import { onMount } from "svelte";
    import { fade } from 'svelte/transition';
    import { get } from 'svelte/store';
    import { devices } from './stores.js'

    import DeviceItem  from "./DeviceItem.svelte";
    
    export let type;
    export let deviceMuted;

    let menu;
    let vTranslate = 0;
    const PAD = 0; //7

    const UnmutedText = {
        mic: "Mute Mic",
        camera: "Mute Camera",
        speaker: "Mute Speaker",
        stream: "Mute Playback"
    }

    const MutedText = {
        mic: "Unmute Mic",
        camera: "Unmute Camera",
        speaker: "Unmute Speaker",
        stream: "Unmute Playback"
    }

    //I think we can remove this
    const deviceMenuClick = (e) => {
        e.stopPropagation(); 
    }


    onMount(() => {
        
        const devicesObj = get(devices);
        const typeDevices = devicesObj[type];
        const selectedLabel = devicesObj.selected[type].label;
        let i = 0;
        let hasSelected = false;

        for (const device of typeDevices){
        
            let selected = false;
            if(selectedLabel.indexOf(device.label) >= 0 && !hasSelected){
                selected = true;
                hasSelected = true;
            }
                
            const deviceItem = new DeviceItem ({ 
                target: menu,
                props: {
                    label: device.label,
                    selected: selected,
                    type: type
                }
            })

            if (i == typeDevices.length - 1){

                let _label;
                deviceMuted ? _label = MutedText[type] : _label = UnmutedText[type];

                const disableItem = new DeviceItem ({ 
                    target: menu,
                    props: {
                        label: _label,
                        selected: selected,
                        type: type,
                        isToggleItem: true
                    }
                })

            }

            i++;
        }
        
        // vTranslate = menu.offsetHeight + document.getElementById("tray").offsetHeight + PAD;
        // vTranslate = '-' + String(vTranslate) + 'px';

        vTranslate = menu.offsetHeight + document.getElementById("tray").clientHeight + PAD;
        vTranslate = '-' + String(vTranslate) + 'px';
        
    })


</script>

    <div id="menu" style="--v-translate: {vTranslate}" bind:this={menu} on:click={deviceMenuClick} on:mousenter={e => console.log('Menu Mouse: ', e)} transition:fade={{duration: 110}}/>

<style>

    #menu {

        position: absolute;
        transform: translate(calc(-50% + 10px), var(--v-translate)); /**27.5 is the width of the icon grid boxes, which remain fixed for now*/

        /* display: flex;
        flex-wrap: nowrap;
        flex-direction: column; */
        display: grid;
        grid-template-columns: 250px;

        border-radius: 10px;
        
        padding-top: 0.75em;
        padding-left: 0.5em;
        padding-right: 0.5em;
        padding-bottom: 0.25em;

        font-size: 14px;
        font-weight: 300;
        font-family: 'Inter', sans-serif;
        color: rgb(252, 249, 246);

        /* color: rgba(171, 168, 168, 0.987); */

        background: #141414e3;
        /* background: #49494985; */
        /* margin-bottom: var(--v-translate); */

        cursor: pointer;
    }

</style>