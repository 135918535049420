

export const StreamParser = {

    /**
     * 
     * @param {*} bytes 
     * @param {*} outputConfig 
     * @returns returns existence of any video frames in this stream message. This is used in Dispatcher to route traffic.
     */
    hasTC(bytes, outputConfig){

        if (!outputConfig)
            return false;

        const stream = new DataView(bytes);
        const header = this.getHeader(stream);
        const tsBlock = new BigInt64Array(bytes, header.headerByteLength, header.numTcPairs * 2); 
        const numLocalFrames = this.getNumLocalFrames(tsBlock, outputConfig);
        return numLocalFrames <= 0 ? false : true;

    },

    /**
     * @param {*} tsBlock Array of global frame numbers and corresponding idxs 
     * @returns Meta object with a list of tcObjects.
     */
    getNumLocalFrames(tsBlock, outputConfig){

        let numLocalFrames = 0;
    
        if(outputConfig && outputConfig.vidlist){
            for (let i = 0; i < tsBlock.length; i += 2){
                const global_frameNum = Number(tsBlock[i]);
                for (const vidStart in outputConfig.vidlist){
                    const vidObj = outputConfig.vidlist[vidStart];
                    if (global_frameNum >= vidObj.start && global_frameNum < vidObj.start + vidObj.length){ 
                        numLocalFrames++;
                    }
                }
            }
        }

        return numLocalFrames;
    },

    getHeader(stream){

        return {
            
            headerByteLength: 8,
            tcByteLength: 160,
            maxAudioLength: 1368, //length of the pre defined audio block (not all is actually used)
    
            numBlocks: stream.getUint8(0, true),
            streamId: stream.getUint8(1, true),
            numTcPairs: stream.getUint8(2, true),
            validAudioLen: stream.getUint16(3, true), //how much of the pre defined audio block is actually used
            frameSize: stream.getUint16(5, true),
            pad: stream.getUint8(7, true),
        }
    }
}


    //TO DO == clean this up?
    // _mapToVidList(tsObjects, outputConfig){
    
    //     let parsedTcObjs = []
    
    //     if (!outputConfig || outputConfig.vidlist.length === 0 || tsObjects.length === 0){  
    //         return []
    //     }
    
    //     for (let i=0; i < outputConfig.vidlist.length; i++){
    
    //         let vidObj = outputConfig.vidlist[i];
    //         for (let j=0; j < tsObjects.length; j++){ 
                
    //             let frame = tsObjects[j].global_ts;
    //             let idx = tsObjects[j].idx;
    
    //             //
    //             if (frame >= vidObj.start && frame < (vidObj.start + vidObj.length)){
    //                 let localFrame = (frame - vidObj.start) + vidObj.offset;
    //                 parsedTcObjs.push({
    //                     id: vidObj.path, 
    //                     frameNum: localFrame, 
    //                     idx: idx, 
    //                     needsFlush: frame + 1 === vidObj.start + vidObj.length ? true : false})
    //             }
                
    //         }
    //     }
    
    //     return parsedTcObjs;
    
    // },



    // _getGlobalTS(tsBlock){

    //     let ts = []
    //     for (let i = 0; i < tsBlock.length; i += 2){
    //         ts.push({global_ts: Number(tsBlock[i]), idx: Number(tsBlock[i + 1])}) //Math.floor(Number(tcBlock[i + 1])/2)
    //     }
    
    //     return ts
    // },

    // /**
    //  * 
    //  * @param {*} bytes ArrayBuffer - Message bytes
    //  * @returns returns the first and last global_ts for this message
    //  */
    // getGlobalTimestamps(bytes){

    //     const stream = new DataView(bytes);
    //     const header = this._getHeader(stream);
    //     const tsBlock = new BigInt64Array(bytes, header.headerByteLength, header.numTcPairs * 2); 
    //     const tsObjects = this._getGlobalTS(tsBlock); //[{global_ts: global_ts, idx: boundry-sample}, ]

    //     if (tsObjects.length === 0)
    //         return null;

    //     return {first: tsObjects[0].global_ts, last: tsObjects[tsObjects.length - 1].global_ts};
    // },

