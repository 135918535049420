<script>

    // class:noAuth = {btnTxtStyleState === 'noAuth'}
    // class:auth = {btnTxtStyleState === 'auth'}>

    import { onMount } from "svelte";
    import { devices, screenName, localTracks } from './stores.js'
    import { createEventDispatcher, tick } from 'svelte';
    import { get } from 'svelte/store' 
    import { CallApi } from './call-api.js'
    import { OutputConfig } from './output-config';
    import { generateDeviceMenu } from './startPanelHelper.js';
    import { DevicesInterface } from "./devicesInterface.js";
    import { Lambdas } from "./lambdas-api.js";
    import { saveUserName} from './utils.js'

    export let userType;

    let startPanel;

    let canJoin = false;
    let isLoaded = false;
    let isAuth = true; //this accomodates non eng loading

    let permissions = false;

    let fullPanel = false;
    let joinBtnText = 'Loading...';
    let optionsText = '...Loading...'

    //USERNAME PROMPT//
    let name = '';
    const NAME_INPUT_PL = 'Enter Name';
    let nameInput = null;
    let nameValid = false;
    let showNameOk = false;

    let permissionReject = false;
    let previewStream = null;
    let video;
    let micMenu; 
    let speakerMenu; 
    let cameraMenu; 
    let streamMenu = null;
    let disableLabel = 'Disable Camera';
    let styleState = 'notReady';
    // let btnTxtStyleState = 'auth';


    const dispatch = createEventDispatcher();

    //we dont get jitsi tracks directly because that requires us to join the conference 
    //which takes too long to do on page load.
    //We could simply init the JitsiMeet object which is a global,
    //In order to make this worthwhile we might have to combine some aws lambda calls
    //Returning the jitsi token with room creds for example

    async function getPermissionsPopulateMenus() {

        try {
        
            try {
                previewStream = await DevicesInterface.deviceRequest({permission: 'all'});

            } catch(error){
                if (error.message.includes(`Requested device not found`)){
                    previewStream = await DevicesInterface.deviceRequest({permission: 'audio'})
                }
            }
            
            previewStream.getAudioTracks().forEach(track => track.stop());
            previewStream.getVideoTracks().forEach(track => track.stop());

            await DevicesInterface.updateDeviceLists();
            generateDeviceMenu(get(devices), userType, disableLabel, micMenu, speakerMenu, cameraMenu, streamMenu);
            await tick(); //make sure the DOM is updated before we use it as basis for updating the svelte store

            //write menu items to the store - this is not really used again outside of the startPanel
            devices.save({
                mic: micMenu,
                speaker: speakerMenu,
                camera: cameraMenu,
                stream: streamMenu
            })

            return Promise.resolve({result: true, reason: 'Permission Granted - Devices Opened'});

        } catch (error){
            console.log(error);
            return Promise.reject({result: false, reason: error});
        }
    }

    //interface
    async function onDeviceChange(e) {

        await tick();
        devices.save({
            mic: micMenu,
            speaker: speakerMenu,
            camera: cameraMenu,
            stream: streamMenu
        })

        if (e.srcElement.id == "cameraSelect"){
            stopPreview();
            await createPreview(get(devices).selected.camera.id);
        }
    }

    async function stopPreview() {
        
        if (video.srcObject)
            video.srcObject.getVideoTracks().forEach(track => track.stop());
    }

    async function createPreview(deviceId = null){

        if (cameraMenu.options[cameraMenu.selectedIndex].value === disableLabel){
            console.warn("Disabling Video")
            return
        }

        if (deviceId)
            previewStream = await DevicesInterface.deviceRequest({preview: true, id: deviceId});

        video.srcObject = previewStream;
    }

    function nameInputClick(){
        showNameOk = false;
        fullPanel = true;
        saveUserName(name);
    }

    function nameInputFocus(type){
        
        if (nameValid)
            return
        if (type === 'blur')
            nameInput.placeholder = NAME_INPUT_PL;
        else if (type === 'focus')
            nameInput.placeholder = '';
    }

    function nameInputVal(value){
        value === '' ? nameValid = false : nameValid = true;
    }

    $: {nameValid ? showNameOk = true : showNameOk = false}
    $: {if (permissionReject){
        optionsText = '...Enable Permissions Please...'
    }}
    
    async function onClick() {

        if (canJoin){
            
            await tick(); 
            devices.save({
                mic: micMenu,
                speaker: speakerMenu,
                camera: cameraMenu,
                stream: streamMenu
            })

            const allDevices = get(devices);
            console.log(allDevices)
            const tracks = await DevicesInterface.openStreams(userType, allDevices.selected).catch(
                error => {
                    console.error(`Stream Error: ${error}`)
                }
            ); 

            localTracks.set(tracks); //need a local copy
            CallApi.setDisplayName(name);
            screenName.set(name); //need a local copy
            saveUserName(name);
            
            //update auth start_time, both on the local copy of the auth_object, and on the remote db
            if (userType === 'eng'){ 
                const a = OutputConfig.initInfo.session.auth; 
                if (a.auth_type === 'session' && a.auth_status === 'pending'){
                    OutputConfig.setAuthStartEnd(a); //mutates passed in object
                    const result = await Lambdas.setSessionStartTime(a.room_name, a.auth_id, a.start_time, a.end_time);
                    console.log(`Have a pending auth: ${a} so updated db`);
                }
                
                OutputConfig.sessionClock.init(a);
            }


            H.identify(name, {

                roomName: OutputConfig.roomName,
                webRTCId: OutputConfig.user,
                confId: CallApi.jitsiConference.myUserId(),
                userType: OutputConfig.initInfo.urlResult.type,
                
            });

            stopPreview(); //causes a layout shift
            dispatch('startSession', {value: true})
        }
        
    }

    onMount(async e => {


        const lsName = localStorage.getItem('pkName');
        if (lsName)
            name = lsName;    
        
        nameInputVal(name);

        let initInterval, authInterval, textInterval;

        if (userType === 'eng'){
            isAuth = 'pending';

            //currently it doesn't make sense that this is an inerval, since there is not any way for the first condition that's checked to be any different. 
            //This will not be the case once the else block checks the auth db and updates the auth_status property.
            authInterval = setInterval(() => {
                if (OutputConfig.initInfo.session.auth.auth_status !== 'inactive'){
                    isAuth = true;
                    clearInterval(authInterval);
                } else {
                    isAuth = false;
                    //TO DO: check auth db
                }

            }, 1000);
        }
            
        //waits for device permissions, and conference joining
        initInterval = setInterval(() => {
            if (OutputConfig.hasJoinedCall && permissions){
                isLoaded = true;
                clearInterval(initInterval);
            }                
        }, 20);


        //set and change text styles based on resource loading and auth status
        textInterval = setInterval(() => {
                
            if (isLoaded && isAuth === true){
                clearInterval(textInterval);
                styleState = 'ready';
                canJoin = true;
                joinBtnText = 'Join';
                
            }

        }, 5);

        getPermissionsPopulateMenus()
        .then(async e => {
            permissions = true;
            await createPreview(get(devices).selected.camera.id); //needs to preview what the selected device is
        })
        .catch(e => {
            console.error(`Device permissions error: ${e.reason.message}`)
            permissionReject = true;
            clearInterval(textInterval);
            clearInterval(initInterval);
            if (authInterval)
                clearInterval(authInterval);

            return
        })

    })


</script>

    <div id="pageWrapper">

        <div bind:this={startPanel} id='startPanel'>
            
            <div id='wrapper'>

                <video class:showFullPanel={fullPanel} class:hideFullPanel={!fullPanel} id="video" autoplay bind:this={video}/>
                
                <div id='name-wrapper'>
                    <input type='text' id='name' spellcheck='false' autocomplete="off" 
                    placeholder="Enter Name"
                    bind:this={nameInput}
                    on:focus={(e) => nameInputFocus(e.type)}
                    on:blur={(e) => nameInputFocus(e.type)}
                    on:input={(e) => {nameInputVal(e.target.value)}}
                    bind:value={name}/>
                    
                    {#if showNameOk && !fullPanel}
                        <button id='name-ok' on:click={nameInputClick}>OK</button>
                    {/if}

                </div>

                <div class="deviceWrapper" class:showFullPanel={fullPanel} class:hideFullPanel={!fullPanel}>
                    <i class="fa-thin fa-video"></i>
                    <select id="cameraSelect" on:change={onDeviceChange} bind:this={cameraMenu}>
                        <option>{optionsText}</option>
                    </select>
                </div>

                <div class="deviceWrapper" class:showFullPanel={fullPanel} class:hideFullPanel={!fullPanel}>
                    <i class="fa-thin fa-microphone"></i>
                    <select id="micSelect" on:change={onDeviceChange} bind:this={micMenu}>
                        <option>{optionsText}</option>
                    </select>
                </div>

                <div class="deviceWrapper" class:showFullPanel={fullPanel} class:hideFullPanel={!fullPanel}>
                    <!-- <i class="fa-thin fa-headphones-simple"></i> -->
                    <i class="fa-thin fa-volume"></i>
                    <select id="speakerSelect" on:change={onDeviceChange} bind:this={speakerMenu}>
                        <option>{optionsText}</option>
                    </select>
                </div>

                {#if userType === 'eng'}
                    
                    <div class="deviceWrapper" class:showFullPanel={fullPanel} class:hideFullPanel={!fullPanel}>
                        <i class="fa-thin fa-signal-stream"></i>
                        <!-- <i class="fa-thin fa-tower-broadcast"></i> -->
                        <select id="streamSelect" on:change={onDeviceChange} bind:this={streamMenu}>
                            <option>{optionsText}</option>
                        </select>
                    </div>
                
                {/if}
                        
                {#if permissionReject}
                    <div id='permsFailed'>
                        <p>Enable Mic And Camera Permissions Please</p>
                        <p><strong>You Can Always Mute Them If You Want</strong></p>
                    </div>
                {:else if !isAuth}
                    <div id='noAuth'>
                        <p>No Active Session</p>
                    </div>
                {:else}
                    <button class:clickable = {styleState === 'ready'} 
                            class:notClickable = {styleState === 'notReady'}
                            class:showFullPanel = {fullPanel}
                            class:hideFullPanel = {!fullPanel}
                            style="margin-top: 0.5em" on:click="{onClick}">
                        <p id='joinBtnText'>{joinBtnText}</p>
                    </button>
                {/if}

            </div>
        </div>

        <div id="termsWrapper">
            <div/>
            <h4 id="terms">by joining you agree to our <a href="https://www.post-link.io/terms" target="_blank">Terms of Service</a> and <a href="https://www.post-link.io/privacy-policy" target="_blank">Privacy Policy</a>.</h4>
            <div/>
        </div>

    </div>


<style>

    .showFullPanel{
        animation-duration: 0.7s;
        animation-iteration-count: 1;
        animation-name: sfp;
    }

    @keyframes sfp {
        from {
            visibility: visible; 
            opacity: 0;
        }

        to {opacity: 1;}
    }

    .hideFullPanel {
        visibility: hidden;
        opacity: 0;
    }

    #permsFailed{
        
        /* width: 120%;
        transform: translate(-8%, 0px); */

        margin-top: 1em;
        padding-top: 0.3em;
        padding-bottom: 0.3em;
        
        border-radius: 10px;

        background: #d00000a8;
    }

    #permsFailed > p {
        color: inherit;
        font-weight: 100;
        font-size: 0.9em;
    }

    #noAuth{
        
        margin-top: 1em;
        padding-top: 0.3em;
        padding-bottom: 0.3em;
        
        border-radius: 10px;

        background: #d00000a8;
    }

    #pageWrapper{

        display: flex;
        flex-direction: column;
        justify-content: space-between;

        height: 95vh;

    }

    #termsWrapper{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    #terms {
        
        background: transparent;
            
        font-family: 'Inter', sans-serif;
        font-size: 0.7em;
        font-weight: 250;
        color: rgb(252, 249, 246);
    }

    a:-webkit-any-link{
        color:#fdc6be;
    }

    #startPanel {
    
        position: relative;

        display: grid;
        grid-template-columns: 1fr 375px 1fr;
        margin-top: 7vh;
        margin-left: 6em;
        margin-right: 6em;


        align-items: center;
        align-content: center;

        font-family: 'Inter', sans-serif;
        font-size: 20px;
        font-weight: 250;
        color: rgb(252, 249, 246);
    }


    #wrapper {

        grid-column: 2/3;

        display: grid;
        grid-template-rows: 225px;
        grid-auto-flow: row;

        justify-content: center;
    }

    .deviceWrapper {
        
        display: grid;
        grid-template-columns: 2em 1fr;

        border-radius: 15px;

        background: #2e2e2e66;
        
        font-family: inherit;
        font-size: 1em;

        padding: 0.5em;

        margin-top: 3px;
        margin-bottom: 3px;
    }

     .fa-thin {
        font-size: 1.2em;
        
    }

    option {
        width: 100%;
    }

    select:hover{
        color: rgba(171, 168, 168, 0.987);
        cursor: pointer;
    }

    select {

        width: 100%;
        background: transparent;
        color: inherit;
        
        border: none;
        box-shadow: none;
        outline: none;

        font-family: inherit;
        font-size: 0.6em;
        font-weight: 300;
    }

    select:focus{
        box-shadow: 0 0 0 4px rgba(139, 131, 142, 0.4);
    }
    

    #video {
        width: 100%;
        height: 100%;
        border-radius: 12px;
        object-fit: cover;
        grid-row: 1/2;
        /* aspect-ratio: 16/9; */
    }

    #name-wrapper{

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
    }

    #name-ok{
        
        width: 100%;
        background: #00a48a91;
        font-size: 0.9em;
        color: #ffd5d5;
        cursor: pointer;

    }

    #name-ok:hover{
        background: #00887191;
    }

    #name {
        
        width: 100%;
        margin-top: 0.7em;
        margin-bottom: 0.3em;

        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: none;
        
        /* box-shadow: none; */
        /* box-shadow: #9E9E9E 0px 0px 5px; */
        /* box-shadow: 0 0 0 4px rgba(139, 131, 142, 0.4); */
        outline: solid #9e9e9e94;
        
        font-family: inherit;
        font-size: 1.2em;
        font-weight: 300;

        color: #ffd5d5;
        background-color: #00000054;

        transition: all 0.3s ease;
    }


    input {

        width: 100%;
        height: 1.5em;
        font-size: 0.8em;

        border-radius: 5px;
        border-width: 0.5px;

        background-color: rgba(255, 255, 255, 0.637);
    }

    #name:focus {
        box-shadow: 0 0 0 4px rgba(139, 131, 142, 0.4);
    }
    

    button {

        display: inline-block;
        height: 2.5em;

        border: 0.05em solid rgba(255, 255, 255, 0.372);
        border-radius: 10px;

        background-color: rgb(10, 10, 10);

        font-family: inherit;
        font-size: 1.1em;
        font-weight: 200;
        text-align: center;
        
    }

    p {
        margin: 0px;
        text-align: center;
        color: #ffd5d5;
    }

    .notClickable {
        box-shadow: none;
    }

    .clickable{
        background: #00a48a91;
        transition: box-shadow 0.18s ease;
    }

    .clickable:hover {
        background: #00887191;
        cursor: pointer;
    }

    #joinBtnText {
        
        /* background: linear-gradient(0deg, #eeaeca, #b0b7df);
        background-size: 200%;   
        -webkit-background-clip: text;
        animation: flow 4s cubic-bezier(0.37, 0.36, 0.75, 0.72) infinite */
    }
    
    /* @keyframes flow {
        from { background-position: left; }
        50% { background-position: right}
        to{ background-position: left;}
    } */
    

</style>