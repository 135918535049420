<script>

    import { onDestroy, onMount } from "svelte";
    import { meetEvents, syncState, screenName, devices, sessionDims } from './stores.js'
    import { get } from 'svelte/store'

    import { CallApi } from './call-api.js'
    import { OutputConfig } from './output-config'
    import { Dispatcher } from "./dispatcher.js";
    import { DevicesInterface } from "./devicesInterface.js";
    import { saveUserName } from "./utils.js";

    export let id;
    export let name;
    
    let audio;
    let video;

    //UI
    const SESSION_MARGINS = 64;
    const SIZE_TRIM = 10; //this is just for design purposes
    let tilesHeight = null;
    let vitHeight = "1px";
    let bgColor = 'transparent'; //#1f1f1f6b

    //STATE
    let hasLoaded = false;
    let showTileMuteIcon = false;
    let showDlSpinner = false;
    let display = 'none';

    //old
    let pbar;   
    let syncSpinner;
    let pct = '0%'; 
    let styleState = 'waiting';

    const meetEventsUnsub = meetEvents.subscribe(async meetEvents => {   

        if (hasLoaded)
            await checkEventQueue(meetEvents.events); 
            
    })

    const unsubSyncState = syncState.subscribe(syncState => {
        if (hasLoaded && id === 'local')
            setDLSpinnerState(syncState.down);
    })


    const sessionDimsUnsub = sessionDims.subscribe(dims => {
        
        //this is to prevent a layout shift when we first load
        if (tilesHeight === null){
            //console.log('Setting provisional height')
            tilesHeight = "100px";
            return;
        }

        const totalHeight = document.documentElement.clientHeight; //does not include scrollbar height
        const offsetHeight = (dims.upperThird.height + SESSION_MARGINS);
        let _tilesHeight = totalHeight - offsetHeight - SIZE_TRIM;
        _tilesHeight < 0 ? _tilesHeight = 0 : _tilesHeight = _tilesHeight;

        tilesHeight = String(_tilesHeight) + 'px';
        
    })

   
    async function onNameChange(e){
        name = e.srcElement.value
        CallApi.setDisplayName(name);
        screenName.set(name); //keep a local copy as well
        saveUserName(name);
    }
    
    function remoteMuteToggle(event){
        
        if (event.track.type === 'video'){
            if (event.track.isMuted()){
                video.style.visibility = 'hidden';
                bgColor = '#1f1f1f6b';
            }

            else {
                video.style.visibility = 'visible';
                bgColor = 'transparent';
            }
        }

        else if (event.track.type === 'audio'){
            event.track.isMuted() ? showTileMuteIcon = true : showTileMuteIcon = false;
        }

    }

    function localCameraToggle(event){

        if (event.isMuted){
            bgColor = '#1f1f1f6b';
            video.style.visibility = 'hidden';
        }

        else {
            video.style.visibility = 'visible';
            bgColor = 'transparent';
        }

    }


    async function checkEventQueue(events){

        for (const [i, event] of events.entries()){
        
            if (event.target === 'tile' && event.id === id && !event.consumed){ 

                try {

                    /**
                     * We cant have any conditionals in these cases that could result in 
                     * not consuming the event. If we await until conditions are met, this event handler can get spuriously recalled
                     */

                    event.consumed = true;
                    switch (event.type){

                        case 'setName':
                            name = event.name;
                            break;

                        case 'setRemoteProgress':
                            setDLSpinnerState(event.progress.dlProgress);
                            break;

                        case 'addTrack':
                            const track = event.track
                            await addTrack(track, id);
                            break;

                        case 'remoteMuteToggle':
                            remoteMuteToggle(event);
                            break;

                        case 'localCameraToggle':
                            localCameraToggle(event);
                            break;


                        case 'localMicToggle':
                            event.isMuted ? showTileMuteIcon = true : showTileMuteIcon = false;
                            break;

                        case 'speakerChange':
                            //console.log(`About to change speaker: ${event.id} ${event.eventId}`);
                            await audio.setSinkId(event.speaker.id);
                            break;

                        case 'speakerMute':
                            audio.muted = event.isMuted;
                            break;

                    }

                    meetEvents.remove(event);

            
                } catch (error){

                    event.consumed = false; 
                    console.error(`Error executing tile event: ${error}`);
                }
            
            }
        }

    }


    async function addTrack(track, id){

        display = 'block';
        if (track.getType() === 'audio') {
            
            if (OutputConfig.talentConfIds.includes(id)){
                console.log("This is a remote talent tile, not adding audio track ", id)
                return
            }

            (id === 'local' || DevicesInterface.muted.speaker === true) ? audio.muted = true : audio.muted = false;
            track.attach(audio);

            //since this is a track, it refers to an input stream, a mic
            if (track.isMuted())    
                showTileMuteIcon = true;

            console.log('Setting Media Stream Sink')
            const speakerId = get(devices).selected.speaker.id;
            await audio.setSinkId(speakerId);
            OutputConfig.tileSinkIds.push(speakerId);
            
            //console.log(OutputConfig.tileSinkIds, OutputConfig.acSinkId)

            // OutputConfig.tileSinkIds.forEach(id => {
            //     if (id !== OutputConfig.acSinkId && OutputConfig.initInfo.urlResult.type !== 'eng'){
            //         console.error('Echo Canellation Id Mismatch', OutputConfig.acSinkId, id);
            //     }
            // })

            audio.autoplay = true;

        }

        else if (track.type === 'video') { 
            track.attach(video) 
        }
    }

    const setDLSpinnerState = (dlProg) => {

        let totalDown = 0;
        if (dlProg)
            totalDown = dlProg * 100;

        if (totalDown > 0 && totalDown < 99.0)
            showDlSpinner = true;

        else if (totalDown == 0 || totalDown >= 99.0)
            showDlSpinner = false;
    }


    onMount(() => {
        hasLoaded = true;
        checkEventQueue(get(meetEvents).events);
        // if (OutputConfig.initInfo.urlResult.type === 'eng'){
        //     setDLSpinnerState(get(syncState).down);
        // }

        if (id === 'local')
            setDLSpinnerState(get(syncState).down); //syncstate is what we call local dlProgress (for historical reasons)

        Dispatcher._resizeCanvas();

    })

    onDestroy(() => {
        meetEventsUnsub();
        unsubSyncState();
        sessionDimsUnsub();
    })


    
//OutputConfig.initInfo.urlResult.type === 'eng' && 
</script>

    <div class='tile' {id} style='--tiles-height: {tilesHeight}; --tile-display: {display}; --vit-height: {vitHeight}; --tile-bg: {bgColor}'>    
        
        <!-- <div id='upWrapper'>
            <div bind:this={pbar} style="width: {pct}" id='up'
                class:waiting = {styleState === 'waiting'} 
                class:downloading = {styleState === 'downloading'} 
                class:synced = {styleState === 'synced'}
            />
        
        
        </div> -->

        
        {#if showDlSpinner} 
            <i bind:this={syncSpinner} class="fa-thin fa-rotate fa-spin" id='syncSpinner'></i>
        {:else}
            <i class="fa-solid fa-circle-small"></i>
        {/if}

        {#if id === 'local' }
            <input spellcheck='false' autocomplete="off" on:input={onNameChange} value={name} class='localUserName'/>
        {:else}
            <input spellcheck='false' value={name} class='peerUserName' readonly/>
        {/if}

        {#if showTileMuteIcon}
            <i class="fa-thin fa-microphone-slash"></i>
        {/if}

        <audio bind:this={audio} autoplay/>
        <video bind:this={video} autoplay class='videointile'/>

    </div>
    

<style>

    .tile {
        
        display: var(--tile-display);
        position: relative;
        background: var(--tile-bg); /**#1f1f1f6b;*/

        height: var(--tiles-height);
        min-height: 80px;
        max-height: 20vh; /*20*/
        width: auto;
        /* max-width: 45%; */

        border: 1px solid #4747475e;
        border-radius: 6px;

        
        /* box-shadow: 1px 1px 6px #cbb2b2d1; */

    }

    
    .videointile {
        position: relative;
        width: 100%;
        height: 100%;   
        border-radius: 6px;
        object-fit: cover;
        z-index: -100;
    }

    .fa-microphone-slash{
        position: absolute;
        top: 8px;
        right: 8px;
        color: rgba(254, 3, 3);
        font-size: 1.3em;
    }
    
    input {

        position: absolute;
        
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: 100;
        
        box-sizing: border-box;
        border: none;
        box-shadow: none;
        outline: none;
        
        font-size: calc(15px + 0.4vw);
        font-family: "Inter", sans-serif;
        /* color: #e5e5e5; */
        color: #ffd5d5;
        background-color: #00000000;
        font-weight: 300;

    }

    .peerUserName {
        -moz-user-select: none;
        user-select: none;
    }

    .localUserName {
        
        width: 98%;
        transform: translate(1%, 0px);

        border-radius: 6px;
        outline: solid #9d9d9d96;
        transition: all 0.3s ease;
    }

    .localUserName:focus {
        box-shadow: 0 0 0 4px rgba(139, 131, 142, 0.4);
    }


    #upWrapper {
        
        position: absolute;
        border: none;
        box-shadow: none;
        outline: none;
        
        background-color: #00000054;
        
        top: 0;
        left: 0;
        width: 100%;
        height: 1.7%;
        border-radius: 1px;
    }

    #syncSpinner {
        position: absolute;
        padding: 5px;
        color: #fafa73;
    }

    .fa-circle-small {
        position: absolute;
        padding: 5px;
        color: #004c00;
        font-size: 0.7em;
    }

    /* #up {
        height: 100%;
        border-radius: 2px;
    } */

    
    /* .waiting {
        visibility: none; 
    }

    .downloading {
        background: rgb(199, 151, 247);
    }

    .synced {
        background: #24481e9d;
       
    } */



</style>