
export const Lambdas = {
    
    async checkURL(){
        
        try {
    
            let msg = JSON.stringify({url: window.location.href})
            let result = await fetch('https://5ejnb29sr2.execute-api.us-east-2.amazonaws.com/checkURL', {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json'}, 
                body: msg
            }).then(response => response.json())
            
            return result
    
        }
    
        catch (error){
            return error
        }
    
    },
    
    async setSessionStartTime(roomName, authId, start_time, end_time){

            try {

                let msg = JSON.stringify({
                    roomName: roomName,
                    authId: authId,
                    start_time: start_time,
                    end_time: end_time
                })

                let result = await fetch('https://jisve7z55dpshzzmjcvfcd4bza0ypcto.lambda-url.us-east-2.on.aws/', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json'}, 
                    body: msg
                }).then(response => response.json())
                
                return result
            }
        
            catch (error){ return error }

    },


    async checkSession(roomName){
    
        try {
    
            let msg = JSON.stringify({roomName: roomName})
            let result = await fetch('https://5ejnb29sr2.execute-api.us-east-2.amazonaws.com/checkSession', {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json'}, 
                body: msg
            }).then(response => response.json())
            
            return result
    
        }
    
        catch (error){
            return error
        }
    },


    async initWebClient() {
        
        try {
            let msg = JSON.stringify({url: window.location.href})
            let result = await fetch('https://7puqdyvoed5wheobokazt3sb340rgnmn.lambda-url.us-east-2.on.aws/', {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json'}, 
                body: msg
            }).then(response => response.json())
            
            return result
    
        }
    
        catch (error){
            return error
        }

    },


    async getRTCCreds(){
    
        try {

            let result = await fetch('https://5ejnb29sr2.execute-api.us-east-2.amazonaws.com/getRTCCreds').then(response => response.json())            
            return result
    
        }
    
        catch (error){
            return error
        }
    }
}





