
import { get } from 'svelte/store';
import { devices, meetEvents, localTracks } from './stores.js'
import { CallApi } from './call-api.js'
import { OutputConfig, BuildSettings } from './output-config.js';
import { OutputInterface } from './output.js';
import { Dispatcher } from './dispatcher.js';

import { nanoid } from 'nanoid/non-secure'

export const DevicesInterface = {

    muted: {
        mic: false,
        camera: false,
        speaker: false, 
        stream: false
    },

    devicePhrases: {
        cameraWasDisabled: ['Disable Camera', 'Mute Camera'],
        toggleDevice: ['Disable', 'Mute', 'Enable', 'Unmute']
    },


    permissionRTypes: {

        all: {
            audio: true, 
            video: {
                width: {max: 854}, 
                height: {max: 480},
                aspectRatio: 16/9
            }
        },

        audio: {
            audio: true,
            video: false
        }
    },

    previewRequest: {
        audio: false,
        video: {
            width: {max: 854}, 
            height: {max: 480},
            aspectRatio: 16/9,
            deviceId: 'default'
        }
    },

    deviceChanging: {
        mic: false, 
        camera: false,
        speaker: false,
        stream: false
    },

    _generateTrackConstraints(request){
        
        let base = structuredClone(CallApi.trackParamsTemplate)
        
        //need to handle no devices existing
        if (request.mic){
            base.devices.push('audio')
            if (request.mic.id){
                base.micDeviceId = request.mic.id;
                base.constraints.audio.micDeviceId = request.mic.id;
            }
        }
        
        if (request.camera && !this.devicePhrases.cameraWasDisabled.includes(request.camera.label)){ 
            base.devices.push('video')
            if (request.camera.id){
                base.cameraDeviceId = request.camera.id
                base.constraints.video = CallApi.videoConstraints
            }
        }

        return base;
    },

    markNewSelections(idObj){

        const type = idObj.type;
        let deviceInfo;


        if (type === 'mic' || type === 'camera'){
            
            let track;
            type === 'mic' ? track = this.getMicTrack() : track = this.getVideoTrack();
            
            if (track){
                deviceInfo = devices.getDeviceInfo({type: type, id: track.getDeviceId()});
                localTracks.update(lt => {
                    lt[type] = track;
                    return lt;
                })
            }
            
            // we device changed during mute so there is no current track (applies only to camera in practice)
            else {
                deviceInfo = devices.getDeviceInfo({type: type, label: idObj.label});
            }
            
        }

        else if (type === 'speaker'){

            const localTile = Array.from(document.getElementById('tiles').children).filter((tile) => tile.id === 'local')[0]; 
            const audio = localTile.getElementsByTagName('audio')[0];
            deviceInfo = devices.getDeviceInfo({type: type, id: audio.sinkId});

        }

        else if (type === 'stream'){

            deviceInfo = devices.getDeviceInfo({type: type, label: idObj.label});
        }

        devices.updateSelectedsObj({[type]: {id: deviceInfo.id, label: deviceInfo.label, groupId: deviceInfo.groupId}})
        devices.updateMenusSelected({type: type, label: deviceInfo.label});        

    },

    mapMuteToggleLabels(type){

        const mapped = DevicesInterface.devicePhrases.toggleDevice.map(x => {
            let phrase = x + ' ' + type.charAt(0).toUpperCase() + type.slice(1);
            return phrase.replace("Stream", "Playback"); //align our terms
        });

        return mapped;

    },

    //returns first audio track -- should only ever be one
    getMicTrack(){
        return CallApi.jitsiConference.getLocalTracks().filter(track => track.getType() == 'audio')[0];
    },

    getVideoTrack(){
        return CallApi.jitsiConference.getLocalTracks().filter(track => track.getType() == 'video')[0];
    },

    async updateDeviceLists(){
        const allDevices = await navigator.mediaDevices.enumerateDevices();
        devices.sort(allDevices);
    },

   
    async initFallbackDevices(){

        const _devices = get(devices);
        for (const type in this.deviceChanging){ //->we are using this object as a source to enumerate the device types
            
            if (OutputConfig.initInfo.urlResult.type !== 'eng' && type === 'stream')
                continue
            
            if(_devices[type].findIndex(d => d.groupId === _devices.selected[type].groupId) < 0 && !this.deviceChanging[type]){

                console.log(`Selected device no longer in list for ${type}, falling back to default`);
                this.deviceChanging[type] = true;    
                await DevicesInterface.changeDevice(type, 'default'); //
                this.deviceChanging[type] = false;
            }

        }
    },

    
    async checkOutputAlignment(userType){

        if (userType !== 'eng'){
            
            const acId = OutputInterface.audioContext.sinkId === '' ? 'default' : OutputInterface.audioContext.sinkId;
            const speaker = get(devices).selected.speaker;
            const spkrGrpId = speaker.groupId;
            const acGrpId = devices.getDeviceInfo({type: 'speaker', id: acId}).groupId;

            if (spkrGrpId !== acGrpId && OutputConfig.initInfo.urlResult.type !== 'eng'){                

                //force stream to be the same as speaker
                console.error('Output Device Mismatch: ', spkrGrpId, acGrpId);
                await OutputInterface.setSpeakerId(speaker.id);
            }
        }

    },


    //This does not update the selecteds store
    async removeVideoTrack(){

        const vTrack = this.getVideoTrack();
        await vTrack.mute(); //remote peers get the event
        await vTrack.dispose(); //removes from DOM element
        vTrack.track.stop();

    },

    
    async retryOpenDevices(callback, args, retries = 3){
        try {
            return await callback(args);
        } catch (error){
            if (error.message.includes('Could not start video source') && retries > 0){
                console.warn(`Camera opening failed, retrying: ${error.message}`)
                return await this.retryOpenDevices(callback, args, retries - 1);
            }
            else
                throw error
        }
    },

    //returns either preview media stream or jitsi tracks in an object
    async deviceRequest(request){
        
        try {

            if (request.permission){
                const requestType = this.permissionRTypes[request.permission];
                return await this.retryOpenDevices(async (_args) => await navigator.mediaDevices.getUserMedia(_args), requestType);
            }

            else if (request.preview){
                let pRequest = structuredClone(this.previewRequest);
                pRequest.video.deviceId = request.id;
                return await this.retryOpenDevices(async (_args) => await navigator.mediaDevices.getUserMedia(_args), pRequest)
            }
            
            else {
                
                const constraints = this._generateTrackConstraints(request);
                const tracks = await this.retryOpenDevices(async (_args) =>  await JitsiMeetJS.createLocalTracks(_args), constraints, 3);
                const tracksObj = {};
                for (const track of tracks){
                    track.getType() === 'video' ? tracksObj.camera = track : tracksObj.mic = track;
                }
    
                return tracksObj;
            }        

        } catch(error){
            throw error
        }

    },

    async openStreams(userType, request){

        try {

            const tracksObj = await this.deviceRequest(request);
        
            if (userType === 'talent'){
                Dispatcher.openTalentStream(request.mic.id);
                tracksObj.mic.track.talent = true;
                //console.log("TALENT TRACK ", tracksObj.mic)
            }

            if (tracksObj.mic)
                await CallApi.jitsiConference.addTrack(tracksObj.mic);

            if (tracksObj.camera)
                await CallApi.jitsiConference.addTrack(tracksObj.camera);

            return tracksObj
        } catch (error){
            throw error
        }

    },

    async addVideoTrack(){

        let label, deviceId;
        const camera = get(devices).selected.camera;
        
        if (!camera.label || this.devicePhrases.cameraWasDisabled.includes(camera.label)){
            label = 'default';
            deviceId = 'default';
        }   
        
        else {
            label = camera.label;
            deviceId = devices.getId({type: "camera", label: label}).id
        }

        //still needs to return default device if prev is not avail (like if the camera was disconnected)
        try {
            const tracksObj = await this.deviceRequest({camera: {id: deviceId, label: label}});
            await CallApi.jitsiConference.addTrack(tracksObj.camera);
            this.markNewSelections({type: 'camera'});
        
        } catch (error){
            throw error;
        }

    },

    /**
     * 
     * unMute:
     *  - get selected device from stores
     *  - create new tracks, if selected device was "Disable", use default device
     * - should we move camera mute out of here since it is really a device change in some ways?
     * 
     */
    async toggleMute(type){
        
        try {

            switch (type) {

                case 'mic':

                    const micTrack = this.getMicTrack();
                    
                    if (micTrack.isMuted()){
                        micTrack.unmute();
                        OutputConfig.talentMuted = false;
                    }
    
                    else {
                        micTrack.mute();
                        OutputConfig.talentMuted = true;
                    }
    
                    meetEvents.add({type: 'localMicToggle', target: 'tile', id: 'local', eventId: nanoid(), isMuted: !this.muted[type]});

                    break;  

                //the only mute case that involves updating the selected device stores (since we add and remove tracks);
                case 'camera':

                    this.muted[type] ? await this.addVideoTrack() : await this.removeVideoTrack(); //these methods update the selected tracks stores internally    
                    meetEvents.add({type: 'localCameraToggle', target: 'tile', id: 'local', eventId: nanoid(), isMuted: !this.muted[type]});
                    
                    break;

                case 'speaker':

                    let tilesToMute = Array.from(document.getElementById('tiles').children).filter((tile) => tile.id !== 'local');
                    tilesToMute.forEach(tile => meetEvents.add({type: 'speakerMute', target: 'tile', id: tile.id, eventId: nanoid(), isMuted: !this.muted[type]}));

                    if (OutputConfig.initInfo.urlResult.type !== 'eng')
                        OutputInterface.setGain(!this.muted[type]);

                    break;

                case 'stream':
                    OutputInterface.setGain(!this.muted[type]);
                    break;
    
            }

            this.muted[type] = !this.muted[type]
            return this.muted[type];
        } catch (error){
            throw error
        }
    },

    async changeDevice(type, label){


        try {

            /// ** handles the mute toggle case
            const mapped = this.devicePhrases.toggleDevice.map(x => {
                let phrase = x + ' ' + type.charAt(0).toUpperCase() + type.slice(1);
                return phrase.replace("Stream", "Playback"); //align our terms
            });
            
            if (mapped.includes(label)){
                return this.toggleMute(type);
            }
            /// ***


            let idObj = devices.getDeviceInfo({type: type, label: label});
            let trackObj;

            switch(type){

                case 'mic':

                    const micTrack = this.getMicTrack();
                    if (micTrack)
                        await micTrack.dispose();

                    trackObj = await this.deviceRequest({mic: {id: idObj.id, label: label}});
                    
                    //Apply this to talent as well
                    if (this.muted.mic)
                        trackObj.mic.mute();
                    
                    if (OutputConfig.initInfo.urlResult.type === 'talent'){
                        Dispatcher.openTalentStream(idObj.id); //this will cancel an existing stream
                        trackObj.mic.track.talent = true;
                    }
                        
                    await CallApi.jitsiConference.addTrack(trackObj.mic);
                    
                    break;


                case 'camera':

                    //if the camera is muted we only need to update the selcted devices stores
                    if(!this.muted.camera){

                        trackObj = await this.deviceRequest({camera: {id: idObj.id, label: label}});
                        
                        const prevVidTrack = this.getVideoTrack();
                        
                        if (prevVidTrack){
                            prevVidTrack.track.stop();
                            await CallApi.jitsiConference.replaceTrack(prevVidTrack, trackObj.camera)   
                        }
                        
                        else 
                            await CallApi.jitsiConference.addTrack(trackObj.camera);          
                    }

                    break;

                case 'speaker':

                    let tilesToChange = Array.from(document.getElementById('tiles').children)
                    
                    for (const tile of tilesToChange){
                        
                        tile.id === 'local' ? 
                        await tile.getElementsByTagName('audio')[0].setSinkId(idObj.id) : //we do this here so that we are sure it gets done before selecteds update
                        meetEvents.add({type: 'speakerChange', target: 'tile', id: tile.id, eventId: nanoid(), speaker: {id: idObj.id, label: label, groupId: idObj.groupId}});

                    }

                    if (OutputConfig.initInfo.urlResult.type !== 'eng')
                        await OutputInterface.setSpeakerId(idObj.id, type); 
                    
                    break;

                case 'stream':
                    
                    await OutputInterface.setSpeakerId(idObj.id, type); 
                    break;
            }

          
            this.markNewSelections(idObj);

            return 'device change'

        } catch(error){
            
            //consider doing a retry here with a default device
            throw error
        }

    }
    
}



