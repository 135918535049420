
export function uuidv4() {
    return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c => 
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16));
}


export function getUnixTime(offset = 0) {
    const now = Math.floor(new Date().getTime() / 1000);
    if (offset === 0) return now;
    else return now + (60 * 60 * 24 * offset);
};

export function saveUserName(name){
    localStorage.setItem('pkName', name);
}




//Under Construction
// export function checkPointersDelta(in_idx, out_idx, arrayLength, ){
        
//     let delta = in_idx - out_idx
    
//     //if true this means the in pointer is behind the out pointer (because it wrapped around)
//     if (delta < 0){  
//         delta = (talentObj.audioArray.length - this.out_idx) + this.in_idx
//     }
    
//     if (delta >= talentObj.prefillThresh) {
//         return true 
//     }
    
//     //this shouldnt happend becuse it means the buffer is still filling while not being emptied 
//     else if (delta == 0 && talentObj.playing) {
//         console.log(`*** AWP: error -- in pointer caught up to out pointer: ${talentObj.talentId}`); 
//         return false
//     } 
    
//     else {
//         return false 
//     } 
// }


//
// export function calculateRatioWidth(height){
    
//     let divisor = 0.5625
//     if (typeof(height) === 'number'){
//         return height / divisor 
//     }
    
//     else if (typeof(height) === 'string'){
//         let numHeight = Number(height.split('px')[0])
//         let width = numHeight / divisor
//         return String(width) + 'px'
//     }
// }
 


/*
pass in an array of percentage that correspond to the distribution of each element
So far we have --> screen, tray, tiles
*/

// export function distributeHeight(totalHeight, screenPercentage, trayPercentage) {

//     let percentages = []
//     let tiles = 1 - screenPercentage - trayPercentage
//     tiles -= 0.03

//     percentages.push(screenPercentage)
//     percentages.push(trayPercentage)
//     percentages.push(tiles)

//     let heights = []
//     for (const percentage of percentages){
//         let height = totalHeight * percentage
//         heights.push(String(height) + 'px')
//     }

//     return heights
// }





// export function syncStateHandler(newState){
        
//     let syncButton = document.getElementById('syncButton')
    
//     if (newState[0].includes('disconnected')){
        
//         let newColor
//         OutputConfig.filesSynced ? newColor = this.syncBorderColors.partial : newColor = this.syncBorderColors.disconnected
//         syncButton.style.borderColor = newColor
    
//     }

//     else if (newState[0].includes('trying') || newState[0].includes('lostConnection')){
        
//         let newColor
//         OutputConfig.filesSynced ? newColor = this.syncBorderColors.partial : newColor = this.syncBorderColors.disconnected
//         syncButton.style.borderColor = newColor
    
//     }
    
//     else if (newState[0].includes('connected')){
        
//         let newColor
//         OutputConfig.filesSynced ? newColor = this.syncBorderColors.synced : newColor = this.syncBorderColors.partial
//         syncButton.style.borderColor = newColor
    
//     }

//     else if (newState[0].includes('uploading')){
        
//         OutputConfig.filesSynced = false
//         if (OutputConfig.hasJoinedCall){
//             this._updateProgBar({local: {total: 0, text: 'uploading new files...'}})
//         }
        
//         this._syncStateHandler([newState[1]])
//     }
// }



// export function populateDeviceMenus(allDevices, startPanel, userType){
        
    
//     let childNodes = startPanel.children
//     let micMenu, speakerMenu, cameraMenu = null

//     for (const node of childNodes){
//         if (node.id === 'micMenu'){ micMenu = node }
//         else if (node.id === 'speakerMenu') { speakerMenu = node}
//         else if (node.id === 'cameraMenu') { cameraMenu = node}
//     }

//     let allAudioInputs = []
//     let allAudioOutputs = [] 
//     let allCameras = []

//     let defInGrpId = null
//     let defOutGrpId = null
//     let opt = null 

//     //Create link options for eng
//     if (userType === 'eng'){
//         opt = document.createElement('option')
//         opt.innerText = 'Pro Tools Link'
//         micMenu.appendChild(opt)
//         allAudioInputs.push({label: 'Pro Tools Link', deviceId:  -1}) 

//         opt = document.createElement('option')
//         opt.innerText = 'Pro Tools Link'
//         speakerMenu.appendChild(opt)
//         allAudioOutputs.push({label: 'Pro Tools Link', deviceId: -1})
//     }

    
//     allDevices.forEach(device => {
        
//         opt = document.createElement('option')
        
//         switch (device.kind){
            
//             case 'audioinput':
                
//                 if (device.groupId === defInGrpId){
//                     break;
//                 }
            
//                 else {

//                     allAudioInputs.push(device)
//                     if (device.deviceId === 'default'){ defInGrpId = device.groupId }
//                     opt.innerText = device.label
//                     micMenu.appendChild(opt)
//                 }
            
//                 break;

//             case 'audiooutput':
                
//                 if (device.groupId === defOutGrpId){
//                     break;
//                 }
            
//                 else {
//                     allAudioOutputs.push(device)
//                     if (device.deviceId === 'default'){ defOutGrpId = device.groupId }
//                     opt.innerText = device.label
//                     speakerMenu.appendChild(opt)
//                 }
                
//                 break;

//             case 'videoinput':
//                 allCameras.push(device)
//                 opt.innerText = device.label
//                 cameraMenu.appendChild(opt)
//                 break;
//         }
        
//     })


//     //Start Mic + Camera muted options
//     opt = document.createElement('option')
//     opt.innerText = 'Start Muted'
//     micMenu.appendChild(opt)
//     allAudioInputs.push({label: 'Start Muted', deviceId: null})


//     opt = document.createElement('option')
//     opt.innerText = 'Start Muted'
//     cameraMenu.appendChild(opt)
//     allCameras.push({label: 'Start Muted', deviceId: null}) 

//     return [allAudioInputs, allAudioOutputs, allCameras]

// }

// export function getDeviceIds (sortedDevices, startPanel) {

//     //we want to retreive deviceIds given labels

//     //pass this section in to make this more abastract
//     let childNodes = startPanel.elements.startPanel.children
//     let micMenu, speakerMenu, cameraMenu = null

//     for (const node of childNodes){
//         if (node.id === 'micMenu'){ micMenu = node }
//         else if (node.id === 'speakerMenu') { speakerMenu = node}
//         else if (node.id === 'cameraMenu') { cameraMenu = node}
//     }

//     //

//     let allAudioInputs = sortedDevices[0]
//     let allAudioOutputs = sortedDevices[1]
//     let allCameras = sortedDevices[2]
    
//     let selectedMic = {}
//     let selectedSpeaker = {}
//     let selectedCamera = {}
//     let micLabel = micMenu.options[micMenu.selectedIndex].value
//     let speakerLabel = speakerMenu.options[speakerMenu.selectedIndex].value
//     let cameraLabel = cameraMenu.options[cameraMenu.selectedIndex].value
    
//     for (const mic of allAudioInputs){
//         if (mic.label === micLabel){
//             selectedMic.id = mic.deviceId
//             selectedMic.label = mic.label
//             break
//         }
//     }

//     for (const speaker of allAudioOutputs){
//         if (speaker.label === speakerLabel){
//             selectedSpeaker.id = speaker.deviceId
//             selectedSpeaker.label = speaker.label
//             break
//         }
//     }

//     for (const camera of allCameras){
//         if (camera.label === cameraLabel){
//             selectedCamera.id = camera.deviceId
//             selectedCamera.label = camera.label
//             break
//         }
//     }

//     return [selectedMic, selectedSpeaker, selectedCamera]
// }









/*
   

    this tries to encode tc into an audio channel somehow

    audio: arrayBuffer (float32)
    tc: int
    duration: int
    (Everything here gets turned into Float32)
    output is [variable length audio arrayBuffer, 25 length seperator, tc, duration]
*/
// export function serializeEncoded(audio, tc, duration){
    
//     let audioFloat = new Float32Array(audio)
//     let seperator = new Float32Array(25).fill(-1)
//     let out = new Float32Array(audioFloat.byteLength + seperator.byteLength)

//     out.set(audioFloat, 0)
//     out.set(seperator, audio.byteLength)
//     //out.set(new Float32Array([tc]), seperator.byteLength)
//     //out.set(new Float32Array([duration]), (seperator.byteLength + 4))
//     return out.buffer
// }




// 16/9 = 1.777777777777778
// export function calcScreenWidth(height){
//     return height / 0.5625

//      //width_offset=1, height_offset=1, 
//     /*
//     let width = 0
//     window.width > 1280 ? width = 1280 : width = window.width - 30
//     width = width - ((1 - width_offset) * width)
    
//     let height = width * 0.5625
//     height = height - ((1 - height_offset) * height)
//     */


// }




// export function resizerHeight(window, string_output=true) {

//     let height = window.height * 0.65
//     let width = height / 0.5625 

//     if (string_output){
//         width = width.toString() + 'px'
//         height = height.toString() + 'px'
//     }

//     //width_offset=1, height_offset=1, 
//     /*
//     let width = 0
//     window.width > 1280 ? width = 1280 : width = window.width - 30
//     width = width - ((1 - width_offset) * width)
    
//     let height = width * 0.5625
//     height = height - ((1 - height_offset) * height)
//     */

//     return {width: width, height: height}

// }


//
// export function calculateSpanWidth(tilesWidth, divideBy){
    
//     if (typeof(tilesWidth) === 'string'){
//         let numWidth = Number(tilesWidth.split('px')[0])
//         let tileWidth = String(numWidth / divideBy) + 'px'
//         return tileWidth
//     }
    
//     else if (typeof(tilesWidth) === 'number'){
//         return String(tilesWidth / divideBy) + 'px'
//     }
    
//     else return null    
    
// }