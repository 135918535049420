import { BuildSettings, OutputConfig } from './output-config'
import { OutputInterface } from './output'
import { CallApi } from './call-api'
import { Dispatcher } from './dispatcher'
import StartPanel from './StartPanel.svelte'
import Session from './Session.svelte'
import { chatMessages, meetEvents } from './stores'
//import { get } from 'svelte/store'
import { nanoid } from 'nanoid/non-secure'
import { DevicesInterface } from './devicesInterface'

export const UI = {

    initCore() { 
        Dispatcher.initCore() 
    },

    //urlResult = {type: 'eng/client/talent', roomName: 'Room Name'}
    loadStart(urlResult){

        const parent = document.getElementById('app')
        const sp = new StartPanel({
            target: parent,
            props: {
                userType: urlResult.type
            }
        })


        this.setSpinner('closed')

        sp.$on('startSession', async () => {
            
            sp.$destroy();
            
            this.setSpinner('open')
            await this.createSession();
        })

        sp.$on('syncSession', () => {
            Dispatcher.syncSessionFile()
        })
        
    },
    
    async initStreaming() { 
        try { await Dispatcher.initStreaming() } 
        catch (error) {
            console.error(`Dispatcher init error: ${error}`)
        }
    },

    joinMeet(){

        if (BuildSettings.DISABLE_CONFERENCE){
            console.error('CONFERENCE DISABLED')
            return
        }
            

        const uiCallbacks = {

            onLocalUserJoin: () => {

                /**
                 * In theory this message could be sent *after* the talent's tile is created by eng (which would result in a double output situation)
                 * However the user joins the conference when the page is loaded, not when they click join. So in practive it is unlikely since they talent has to enter name, and do device selection,
                 * before tile is created. That said, how can we fix this?
                 */

                if (OutputConfig.initInfo.urlResult.type === 'talent'){
                    const talentId = CallApi.jitsiConference.myUserId();
                    console.log('Sending Talent Id: ', talentId)
                    CallApi.jitsiConference.sendTextMessage(JSON.stringify({to: 'eng', from: talentId, payload: {talentId: talentId}}))
                }
                
                OutputConfig.hasJoinedCall = true;

                meetEvents.add({type: 'createTile', target: 'session', id: 'local', eventId: nanoid(), consumed: false});

            },

            onRemoteUserJoin: (id) => {
                meetEvents.add({type: 'createTile', target: 'session', id: id, eventId: nanoid(), consumed: false});
            },

            onUserLeft: (id, user) => {
                

                //console.log('USER LEFT ', id, user) 
                //remove tile
                const tile = document.getElementById(id);
                if (tile) tile.remove();
                
                //remove any pending events with this id
                meetEvents.userLeft(id);

                //remove from talentList if it exists there
                if (OutputConfig.initInfo.urlResult.type === 'eng'){
                    OutputConfig.talentConfIds.forEach((tId, i, arr) => {
                        if (id === tId) {
                            console.log("Removing Talent ID ", id)
                            arr.splice(i, 1);
                        }
                    })
                }
                
            },

            trackAdded: (track, id) => {
                //console.log("Creating Add Track Event: ", id, track)
                meetEvents.add({type: 'addTrack', target: 'tile', id: id, track: track, eventId: nanoid()});

            },
            
            setTalentId(talentId){
                OutputConfig.talentConfIds.push(talentId);
                console.log("Adding Talent ID: ", talentId)
            },
            
            //use this to handle remote audio mutes, so that we can add an icon
            remoteMuteToggle(track){
                meetEvents.add({type: 'remoteMuteToggle', target: 'tile', id: track.ownerEndpointId, track: track, eventId: nanoid()});
            },

 
            setChatMessage: (msg) => {
                
                chatMessages.update(msgObject => {
                    msgObject.unread++;
                    msgObject.msgs.push(msg.payload);
                    return msgObject;
                })
            },

            setGetUserName: (type, from, text) => {

                if (type === 'set')
                    meetEvents.add({type: 'setName', target: 'tile', eventId: nanoid(), id: from, name: text});
            },

            setGetRemoteProgress: (type, from, payload) => {
                
                if (type === 'set'){
                    const updateEvent = {type: 'setRemoteProgress', target: 'tile', eventId: nanoid(), id: from, progress: payload};
                    meetEvents.updateRemoteProgress(updateEvent);

                }
            
            }
        }
        
        try {
            CallApi.joinConference(OutputConfig.initInfo.urlResult, uiCallbacks);
        } catch (error){
            console.error(`Error joining conference: ${error}`);
        }

        

    },


    async createSession () {

        const parent = document.getElementById('app')
        
        const session = new Session({
            target: parent,
            props: {
                setSpinner: this.setSpinner.bind(this),
                userType: OutputConfig.initInfo.urlResult.type,
            }
        })

        const result = await Dispatcher.initOutput();

        if (result === 'ok'){
            
            //console.log('[Session created]');
            OutputConfig.hasJoinedSession = true;
            
            //check if we are in playback already
            let _transport = {transport: false};
            OutputConfig.isPlaying === true ? _transport.transport = 'playing' : _transport.transport = 'stopped';  
            Dispatcher.decoderThread.postMessage(_transport);
            
            await DevicesInterface.checkOutputAlignment();

            session.$on('syncSession', () => {
                Dispatcher.syncSessionFile()
            })
        }

        else {
            console.error(result);
            //UI HERE
        }
        
    },

    setSpinner(setTo){

        if (setTo === 'open'){
            let parent = document.getElementById('app')
            let loader = document.createElement('div')
            loader.className = 'loader'
            parent.prepend(loader)
        }

        else if (setTo === 'closed'){
            let loader = document.getElementsByClassName('loader')[0]
            if (loader !== undefined){
                loader.remove()
            }
        }
    },
}
    



// DISABLED FOR TEST //
                // if (type === 'set') {
                    
                //     meetEvents.update(events => {
                        
                //         const update = {type: 'setRemoteProgress', id: from, progress: payload} 
                        
                //         let replaced = false;
                //         events.forEach((me, i) => {
                //             if (me.type === 'setRemoteProgress' && me.id === from){
                //                 events[i] = update;
                //                 replaced = true;
                //             }
                //         })
                        
                //         if (!replaced) events.push(update);

                //         return events
                //     })        
                // }