
import { BuildSettings, OutputConfig } from "./output-config.js";

/**
 * jaas example here: 
 * https://github.com/saghul/sandbox-html/blob/main/jaas-ljm/example.js
 * 
 */

export const CallApi = {
    
    userName: '',

    jitsiConnection: null,
    jitsiConference: null,

    videoConstraints: {
        aspectRatio: 1.777777778,
        framerate: {ideal: 30, max: 30, min: 15},
        width: {max: 640}, //256
        height: {max: 360} //144
    },
    
    // we clone this and selectively mutate the deep copy
    trackParamsTemplate: {
        devices: [], 
        micDeviceId: null,
        cameraDeviceId: null,
        minFps: 15,
        maxFps: 30,
        constraints: {
            video: false,
            audio: {
                micDeviceId: "",
            },
            
            desktop: false 
        }, 
    },

    gumRetries: 0,

    
    setDisplayName(name) {

        if(this.jitsiConference){
            this.jitsiConference.setDisplayName(name)
        }

    },

    buildOptions(tenant, room) {
            
        return {    
            
            hosts: {
                domain: `8x8.vc`,
                muc: `conference.${tenant}.8x8.vc`,
                focus: `focus.8x8.vc`
            },
            
            serviceUrl: `wss://8x8.vc/${tenant}/xmpp-websocket?room=${room}`,   //?release=release-3792
            websocketKeepAliveUrl: `https://8x8.vc/${tenant}/_unlock?room=${room}`,
    
            // Video quality / constraints
            constraints: {
                video: {
                    framerate: {ideal: 30, max: 30, min: 15},
                    width: {max: 640}, 
                    height: {max: 360},
                    aspectRatio: 1.777777778,
                }
            },

            channelLastN: 7, //simultaneous number of videos that get sent from video bridge. 
            stereo: false, //it's possible this disables echo cancellation?
            enableLayerSuspension: true,
            disableSimulcast: false,
        
            // Enable Peer-to-Peer for 1-1 calls
            p2p: {
                enabled: false
            },
    
            // Enable Callstats (note, none of this is secret, despite its name)
            // callStatsID: '706724306',
            // callStatsSecret: 'f+TKWryzPOyX:dNR8PMw42WJwM3YM1XkJUjPOLY0M40wz+0D4mZud8mQ=',
            // confID: `https://8x8.vc/${tenant}/${room}`,
            // siteID: tenant,
            // applicationName: 'Postlink',
    
            // // Misc
            // deploymentInfo: {userRegion: 'default'},
    
            // // Logging
            logging: {
    
                // Default log level
                defaultLogLevel: 'warn',
                // we can set individual log levels for each module
                'modules/RTC/TraceablePeerConnection.js': 'warn',
                'modules/statistics/CallStats.js': 'warn',
                'modules/xmpp/strophe.util.js': 'warn'
            },
    
            // End marker, disregard
            __end: true
            
        };
    },



    joinConference(urlResult, uiCallbacks){

        return new Promise(async (resolve, reject) => {
        
            let roomName = urlResult.roomName;
            let token = await fetch('https://mcttj07x52.execute-api.us-east-2.amazonaws.com/default/JitsiJWT_js').then(response => response.json())
            OutputConfig.roomName = roomName;
            let credentials = {tenant: 'vpaas-magic-cookie-93bb45ae36294f9d8f62f268db014731', token: token.body, room: roomName} //return the tenant from token fetch. (even though it is always the same)

            const options = this.buildOptions(credentials.tenant, credentials.room);
            JitsiMeetJS.init(options);
            
            JitsiMeetJS.setLogLevel(options.logging.defaultLogLevel);
            for (const [loggerId, level] of Object.entries(options.logging)) {
                if (loggerId !== 'defaultLogLevel') {
                    JitsiMeetJS.setLogLevelById(level, loggerId);
                }
            }

            //maybe move this to dispatcher
            // if (this.isLinkedInput){
            //     this.linkedAudioInput.init()
            // }
                        
            this.jitsiConnection = new JitsiMeetJS.JitsiConnection(null, credentials.token, options);
           
            this.jitsiConnection.addEventListener(
                
                //MOST CALLBACKS THAT OCCUR DURING A CALL ARE INSIDE HERE
                JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED, () => {
                    
                    this.jitsiConference = this.jitsiConnection.initJitsiConference(credentials.room, options)
                    
                    this.jitsiConference.on(
                        
                        JitsiMeetJS.events.conference.CONFERENCE_JOINED, async () => {
                            //console.log('[** LOCAL USER JOINED: **]', 'local')
                            uiCallbacks.onLocalUserJoin();
                            resolve(true);
                        }     
                    );
                    
                    this.jitsiConference.on(
                        
                        //TRACK ADDED
                        JitsiMeetJS.events.conference.TRACK_ADDED, track => {
                            let selector;
                            track.isLocal() ? selector = 'local' : selector = track.getParticipantId();
                            uiCallbacks.trackAdded(track, selector);                            
                            
                        }
                    );
                
                    this.jitsiConference.on(
                        JitsiMeetJS.events.conference.TRACK_REMOVED, (track) => {
                            //console.log('[**TRACK REMOVED**]', track)
                        }
                    );

                    this.jitsiConference.on(
                        JitsiMeetJS.events.conference.TRACK_MUTE_CHANGED, (track) => {
                            if (!track.isLocal()){

                                //console.log('[**NON LOCAL TRACK MUTE CHANGED**]', track)
                                uiCallbacks.remoteMuteToggle(track);
                                //use this if we want remote ismuted icons
                            
                            }

                            // else{
                            //     uiCallbacks.remoteMuteToggle(track);
                            // }
                            
                        }
                    );

                    this.jitsiConference.on(
                        JitsiMeetJS.events.conference.LAST_N_ENDPOINTS_CHANGED, (entering, leaving) => {
                            console.log('[**LAST N ENDPOINTS CHANGED: (entering, levaing) **]', entering, leaving)
                        }
                    );

                    
                    this.jitsiConference.on(
                        JitsiMeetJS.events.conference.USER_JOINED, (id, user) => {
                            console.log('[** REMOTE USER JOINED: **]', id);                            
                            uiCallbacks.onRemoteUserJoin(id)
                            
                        }
                    );


                    this.jitsiConference.on(
                        JitsiMeetJS.events.conference.NO_AUDIO_INPUT, () => {
                            console.error('#### No Audio Input');
                        }
                    );

                    this.jitsiConference.on(
                        //Fires for remote users
                        JitsiMeetJS.events.conference.DISPLAY_NAME_CHANGED, (id, text) => {
                            //console.log('#### Display Name Changed ', id, text)
                            uiCallbacks.setGetUserName('set', id, text);
                        }
                    );


                    this.jitsiConference.on(
                        JitsiMeetJS.events.conference.MESSAGE_RECEIVED, (id, text, ts) => {

                            let msg = JSON.parse(text)
                            let localId = this.jitsiConference.myUserId()

                            if (msg.from !== localId && msg.to === localId){ //we get our own sent messages, so we can ignore those

                                if (msg.payload.getRemoteProgress){
                                    uiCallbacks.setGetRemoteProgress('get', msg.from, null)
                                }
                                
                                else if (msg.payload.setRemoteProgress){
                                    uiCallbacks.setGetRemoteProgress('set', msg.from, msg.payload.setRemoteProgress)
                                }
                            
                            }

                            //Session Chat Will Use This
                            else if (msg.from !== localId && msg.to === 'all'){
                                
                                if (BuildSettings.DEBUG && msg.payload.text === 'end demo'){
                                    this.cleanupLocal();
                                    window.location.replace('https://www.post-link.io');
                                }
                                
                                uiCallbacks.setChatMessage(msg);
                                
                            }

                            //this is for receiving a 'broadcast to eng' case (messages without a get + set exchange)
                            else if (msg.to === 'eng' && OutputConfig.initInfo.urlResult.type === 'eng'){
                                
                                if (msg.payload.talentId) 
                                    uiCallbacks.setTalentId(msg.payload.talentId);
                                
                                else if (msg.payload.setRemoteProgress)
                                    uiCallbacks.setGetRemoteProgress('set', msg.from, msg.payload.setRemoteProgress)
                            }

                        });


                    //When this user leaves the conference
                    this.jitsiConference.on(
                        
                        JitsiMeetJS.events.conference.CONFERENCE_LEFT, (id, user) => {
                            this.cleanupLocal();
                        }       
                    );

                    //When other users leave the conference
                    this.jitsiConference.on(
                        JitsiMeetJS.events.conference.USER_LEFT, (id, user) => {
                            uiCallbacks.onUserLeft(id, user);
                        }
                    );
                    
                    this.jitsiConference.join();
                    this.jitsiConference.setSenderVideoConstraint(360);
                    this.jitsiConference.setReceiverVideoConstraint(360);
    
                }
            );
            
            this.jitsiConnection.addEventListener(
                JitsiMeetJS.events.connection.CONNECTION_FAILED, () => {
                        console.log('*** CONNECTION ERROR ***')

                        //cleanup and then re - connect
                        //this.cleanupLocal();
                }
            );

            this.jitsiConnection.addEventListener(
                JitsiMeetJS.events.connection.CONNECTION_DISCONNECTED, () => {
                    console.log('*** DISCONNECT ***')
                    this.cleanupLocal();
                }
            );
            
            this.jitsiConnection.connect();

        });
    
    },
    
    
    cleanupLocal(){

        console.log('[DISPOSING CONF AND CONNECTION]')
        //Maybe Need a redirect here --> Just a reload? or reconnection attempt loop

        if (this.jitsiConference){
            
            const localTracks = this.jitsiConference.getLocalTracks();

            localTracks.forEach(async t => {
                await t.dispose();
                //this.jitsiConference.removeTrack(t)
            })

            this.jitsiConference.leave();
            this.jitsiConference = null;

        }
        
        if (this.jitsiConnection) {
            this.jitsiConnection.removeEventListener
            this.jitsiConnection.disconnect();
            this.jitsiConnection = null;
        }

    },


}


