export const xsysApi = {

    //get the best host
    async hostCall(user, creds){
        let endPoint = 'https://global.xirsys.net/_host?type=signal&k=' + user
        //let endPoint = 'https://global.xirsys.net/_host?type=signal'
        let headers = new Headers()
        headers.append("Authorization", "Basic " + btoa(creds))
        const params = {
            method: 'GET',
            headers: headers,
        }
        return await fetch(endPoint, params)
    
    },

    
    async tokenCall(channel, sender, creds){
        let endPoint = 'https://global.xirsys.net/_token/' + channel + '?k=' + sender + '&expire=30'
        let headers = new Headers()
        headers.append("Authorization", "Basic " + btoa(creds))
        const params = {
            method: 'PUT',
            headers: headers,
        }
        return await fetch(endPoint, params)
    
    },
    
    async turnCall(channel, creds){
        let endPoint = 'https://ws.xirsys.com/_turn/' + channel
        let headers = new Headers()
        headers.append("Authorization", "Basic " + btoa(creds))
        headers.append("Content-Type", "application/json");
        const params = {
            method: 'PUT',
            headers: headers,
            body: JSON.stringify({"format": "urls"}) 
        }
        return await fetch(endPoint, params)
    
    },

    async deleteChannel(channel, creds){
        //let outConfigKey = channel + '_outputConfig'
        //let endPoint = 'https://global.xirsys.net/_subs/' + channel
        let endPoint = 'https://global.xirsys.net/_ns/' + channel
        let headers = new Headers()
        headers.append("Authorization", "Basic " + btoa(creds))
        const params = {
            method: 'DELETE',
            headers: headers,
            keepalive: true
        }
        
        return await fetch(endPoint, params)
    },


    async createChannel(channel, creds){
        let endPoint = 'https://global.xirsys.net/_ns/' + channel
        let headers = new Headers()
        headers.append("Authorization", "Basic " + btoa(creds))
        //headers.append("Content-Type", "application/json")
        const params = {
            method: 'PUT',
            headers: headers,
        }
        return await fetch(endPoint, params)
    
    },

    async getConnectedUsers(channel, creds){
        let endPoint = 'https://global.xirsys.net/_subs/' + channel;
        let headers = new Headers();
        headers.append("Authorization", "Basic " + btoa(creds));
        //headers.append("Content-Type", "application/json")
        const params = {
            method: 'GET',
            headers: headers,
        }

        const result = await fetch(endPoint, params);
        return await result.json();

    },

    async removeUser(channel, creds, user){

        let endPoint = 'https://global.xirsys.net/_subs/' + channel + "?k=" + user;
        let headers = new Headers()
        headers.append("Authorization", "Basic " + btoa(creds))
        //headers.append("Content-Type", "application/json")
        const params = {
            method: 'DELETE',
            headers: headers,
            keepalive: true,
        }

        const result = await fetch(endPoint, params);
        return await result.json();
    },

    async connectToSignaling(channel, user, creds){

        let tokenResponse = await this.tokenCall(channel, user, creds)
        let token = await tokenResponse.json()
        token = token.v

        let hostResponse = await this.hostCall(user, creds)
        let host = await hostResponse.json()
        host = host.v
        let url = host + '/v2/' + token

        return new WebSocket(url) 

    },

    async genereateIceObjects(channel, creds){
        
        let iceResponse = await xsysApi.turnCall(channel, creds)
        let iceObjects = await iceResponse.json()
        return iceObjects.v

    },


}