import { writable } from 'svelte/store'

function Devices() {

    
    const {set, update, subscribe} = writable({
        mic: [],
        speaker: [],
        camera: [],
        stream: [],
        screen: [],
        selected: {mic: {}, speaker: {}, camera: {}, stream: {}, screen: {}}, //{id: "", label: ""}
        menus: {mic: null, speaker: null, camera: null, stream: null, screen: null},
    })

    return {
        
        set,

        //{type: mic, label: ''}
        updateMenusSelected: (newSelected) => {
            
            update(devices => {
               
                let i = 0;
                for (const option of devices.menus[newSelected.type].options){
                    if (option.innerText === newSelected.label){
                        devices.menus[newSelected.type].options.selectedIndex = i;
                        break;
                    }

                    i++;
                }
            
                return devices
            });

        },

        updateSelectedsObj: (newSelected) => {
            
            update(devices => {
                
                if (newSelected.mic)
                    devices.selected.mic = newSelected.mic;
                
                if (newSelected.speaker)
                    devices.selected.speaker = newSelected.speaker;

                if (newSelected.camera)
                    devices.selected.camera = newSelected.camera;

                if (newSelected.stream)
                    devices.selected.stream = newSelected.stream

                if (newSelected.screen)
                    devices.selected.screen = newSelected.screen
                
                return devices;

            })
        },

        //This is called when modal devices window is called, and sets selected devices object. explicitDisable being false means we dont erase the perviously selected
        //camera. This is so the 'disable camera' option can be reversed (and we return to the same device) by toggling the mute button (which we do without entering the modal menu) 
        //
        save: (menus, explicitDisable = true) => {
            
            update(devices => {

                for (const type in menus){
                    
                    let selected = null;
                    const menu = menus[type];
                    if (menu) 
                        selected = menu[menu.selectedIndex]
                    
                    if (selected && selected.innerText.includes('Disable Camera') && explicitDisable){
                        devices.selected[type].id = null;
                        devices.selected[type].label = 'Disable Camera';
                        devices.selected[type].groupId = null;
                        continue
                    }

                    for (const device of devices[type]){
                        
                        if (selected && device.label === selected.innerText){
                            devices.selected[type].id = device.deviceId;
                            devices.selected[type].label = device.label;
                            devices.selected[type].groupId = device.groupId;
                        }

                    }

                    devices.menus[type] = menus[type];
    
                }
                
                return devices
            })

        },


        //paramObj {type: type, label: [maybe label], id: [maybe id]}
        getDeviceInfo: (paramObj) => {

            const deviceObj = {};
            deviceObj.label = 'default';
            deviceObj.id = 'default';
            deviceObj.type = paramObj.type;
            let term = Object.keys(paramObj).filter(k => k !== 'type')[0]; //term is what we 'search' by, and it is just any property of the passed in object that is not 'type'
            let deviceTerm = term;

            if (term === 'id') //align our terms
                deviceTerm = 'deviceId'

            update(devices => {
               devices[paramObj.type].forEach(device => {
                    if (device[deviceTerm] === paramObj[term]){
                        deviceObj.id = device.deviceId;
                        deviceObj.groupId = device.groupId;
                        deviceObj.label = device.label
                    }
               })

               return devices
            })    


            return deviceObj;
        },

        getId: (labelObj) => {
            
            let idObj = {};
            idObj.type = labelObj.type;
            idObj.id = 'default';
            idObj.label = 'default';

            update (devices => {
                devices[labelObj.type].forEach(device => {
                    if (device.label === labelObj.label){
                        idObj.id = device.deviceId;
                        idObj.groupId = device.groupId;
                    }
                        
                })

                return devices

            })
            
            return idObj

        },


        sort: (newDevs) => {
            
            update(currentDevs => {

                currentDevs.camera = [];
                currentDevs.mic = [];
                currentDevs.screen = [];
                currentDevs.speaker = [];
                currentDevs.stream = [];
               
                newDevs.forEach(newDev => {
                    
                    if (newDev.kind === 'audioinput') currentDevs.mic.push(newDev); 
                    else if (newDev.kind === 'audiooutput') {
                        currentDevs.stream.push(newDev.toJSON())
                        currentDevs.speaker.push(newDev)
                    }
                    else if  (newDev.kind === 'videoinput') {
                        if (!newDev.label.includes("IoXT"))
                            currentDevs.camera.push(newDev)
                    }
                    else if  (newDev.kind === 'videooutput') currentDevs.screen.push(newDev);
                })
    
                return currentDevs

            })},

        subscribe,

    }

}

export const screenName = writable('');
export const devices = Devices();
//export const deviceMenus = writable({})//{mic: null, camera: null, speaker: null, stream: null} //hold html <select> elements


//need this really only as an argument to the Session component - they are read onMount. 
//if there is a better way to do that we can get rid of this - all other code should get the local tracks directyl form the jitsi api
export const localTracks = writable({mic: null, camera: null}) 

export const meetUsers = writable({}); //id: userObject (this is supplied by the JitsiAPI)
function MeetEvents(){

    const {set, update, subscribe} = writable({events: [], lastOp: null})
    return {
            
        subscribe,
        
        add: (event) => update(meetEvents => {
                meetEvents.events.push(event);
                meetEvents.lastOp = event.type;
                return meetEvents;
            }),

        
        userLeft: (id) => update(meetEvents => {
            meetEvents.events = meetEvents.events.filter(e => e.id !== id);
            meetEvents.lastOp = 'userLeft';
            return meetEvents;
        }),

        remove: (event) => update(meetEvents => {
            meetEvents.events = meetEvents.events.filter(e => e.eventId !== event.eventId);
            meetEvents.lastOp = event.type;
            return meetEvents;
        }),

        updateRemoteProgress: (event) => update(meetEvents => {
            const i = meetEvents.events.findIndex(me => me.type === 'setRemoteProgress' && me.id === event.id)
            i >= 0 ? meetEvents.events[i] = event : meetEvents.events.push(event);
            meetEvents.lastOp = event.type;
            return meetEvents;
        }),


        cleanup: () => update(meetEvents => {
            
                meetEvents.events = meetEvents.events.filter(e => !e.consumed);
                meetEvents.lastOp = 'cleanup';
                //console.log(`Cleaning events`)

                return meetEvents;
            }),
            
    };
}

export const meetEvents = MeetEvents();
//export const meetEvents = writable([]);

export const linkState = writable('disconnected');
export const syncState = writable({}); //key: {up: 100, down: 100}

export let chatMessages = writable({unread: 0, msgs: []});
// export const sessionDims = writable({screen: {height: 0, width: 0}, tray: {height: 0}});
export const sessionDims = writable({upperThird: {height: 0, width: 0}});

